import React, { useState, useEffect } from "react";
import {  DataGrid, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarContainer,
  GridToolbarExport, GridToolbarQuickFilter, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { db, app } from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  where,
  orderBy,
  query,
  arrayRemove,
} from "firebase/firestore";
import { useAuth } from "../../contexts/authcontext";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Box, Button, Chip } from "@mui/material";
import "@fontsource/roboto/300.css";
import { useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import Badge from "@mui/material/Badge";
import { FormControlLabel, IconButton } from "@mui/material";
import { blue, pink} from "@mui/material/colors";
import { Edit } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { Stack } from "@mui/system";
import {
  obtenerUIDProyecto,
  obtenerNOMProyecto,
  generateRandomPassword,
  obtenerFechaYHoraActual,
  localeText,
} from "../functions";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import InputGroup from "react-bootstrap/InputGroup";
import RefreshIcon from "@mui/icons-material/Refresh";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import passwordValidator from "password-validator";
import PasswordIcon from "@mui/icons-material/Password";
import CheckIcon from "@mui/icons-material/Check";
import Switch from '@mui/material/Switch';
import "../../components/styles.css";

const ListUsuarios = () => {
  const functions = getFunctions(app);
  // Obtener la referencia a la función "createUser"
  const createUser = httpsCallable(functions, "createUser");
  const [selectionModel, setSelectionModel] = useState([]);
  const [data, setData] = useState([]);
  const [showModalForm, setshowModalForm] = useState(false);
  const [refreshTable, setrefreshTable] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [deletelabel, setdeletelabel] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [chipProyectos, setChipProyectos] = useState([]);
  const [action, setAction] = useState('new'); // 'new' para crear, 'edit' para editar
  const [checked, setChecked] = useState(true);

  const { selproyecto, user, setNumUsuarios, setMensaje, setBackdrop } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: dataEdit,
  });

  const schemaPassword = new passwordValidator();
  const schemaUsuario = new passwordValidator();

  schemaPassword
    .is()
    .min(6) // Mínimo de 6 caracteres
    .has()
    .uppercase(1) // Al menos una letra mayúscula
    .has()
    .lowercase(1) // Al menos una letra minúscula
    .has()
    .digits(1) // Al menos un número
    .has()
    .symbols(1) // Al menos un carácter especial
    .not()
    .spaces(); // No debe contener espacios

schemaUsuario
    .is().min(5)                      // Mínimo 5 caracteres
    .is().max(20)                     // Máximo 20 caracteres
    .has().lowercase()                // Al menos una minúscula
    .has('.');                        // Permite solo el carácter "."
  
schemaUsuario
    .has().not().symbols()            // No permite otros símbolos
    .has().not().uppercase();         // No permite mayúsculas

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    loadUsuarios();
    loadClientes();
    loadProyectos();
  }, [selproyecto]);

  useEffect(() => {
    reset(dataEdit);
  }, [dataEdit, reset]);

  const loadUsuarios = async () => {
    setrefreshTable(true);
    const q = query(
      collection(db, "usuarios"),
      where("proyectos", "array-contains", obtenerUIDProyecto()),
      orderBy("usuario", "asc")
    );
    const querySnapshot = await getDocs(q)
      .then((querySnapshot) => {
        const datos = [];
        querySnapshot.forEach((doc) => {
          
          datos.push({
            id: doc.id,
            email: doc.data().correo,
            nombre: doc.data().usuario,
            comentarios: doc.data().comentarios,
            activo: doc.data().activo === true ? true : false,
            empresa: doc.data().empresaNOM,
            userAccess: doc.data().userAccess,
            proyectos: doc.data().proyectos,
          });
        });
        setData(datos);
        setNumUsuarios(datos.length);
        setrefreshTable(false);
      })
      .catch((error) => {
        
        setMensaje({
          show: true,
          texto: `Ha ocurrido un problema de lectura al cargar los Usuarios!`,
          color: "error",
        });
      });
  };

  const loadClientes = async () => {
    const q = query(
      collection(db, "clientes"),
      where("proyectoID", "==", obtenerUIDProyecto()),
      where("activo", "==", true),
      orderBy("nombre", "asc")
    );
    const querySnapshot = await getDocs(q);

    const datos = [{ id: 0, nom: "Selecciona empresa..." }];
    querySnapshot.forEach((doc) => {
      datos.push({
        id: doc.id,
        nom: doc.data().nombre,
      });
    });
    setClientes(datos);
  };

  const loadProyectos = async () => {
    setChipProyectos([{ id: obtenerUIDProyecto(), nom: obtenerNOMProyecto() }]);
    const q = query(
      collection(db, "proyectos"),
      where("createBy", "==", user.uid),
      orderBy("nombre", "asc")
    );
    const docSnap2 = await getDocs(q)
      .then((querySnapshot) => {
        const arrayproyectos = [
          { id: 0, nom: "Selecciona otros proyectos..." },
        ];
        querySnapshot.forEach((doc) => {
          arrayproyectos.push({ id: doc.id, nom: doc.data().nombre });
        });
        setProyectos(arrayproyectos);
      })
      .catch((error) => {
        console.error("Error al obtener los proyectos:", error);
      });
  };

  const findNomById = (id) => {
    const obj = proyectos.find((item) => item.id === id);
    return obj ? obj.nom : null;
  };

  const CustomHeaderAcceso = () => {
    return (
      <div style={{lineHeight: '2px', textAlign:'center', marginTop:'15px' }}>
        <p style={{fontWeight:'bold', fontSize: '14px', alignContent:'center'}}>Acceso</p>
        <p  style={{  fontSize: '12px' }}>@tickme.com</p>
      </div>
    );
  };

  const CustomHeaderCorreo = () => {
    return (
      <div style={{lineHeight: '2px', textAlign:'center', marginTop:'15px' }}>
        <p style={{fontWeight:'bold', fontSize: '14px', alignContent:'center'}}>Correo</p>
        <p  style={{  fontSize: '12px', color: 'GrayText' }}>(notificaciones)</p>
      </div>
    );
  };

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "nombre", headerName: "Usuario", width: 200 },
    { field: "empresa", headerName: "Empresa", width: 150 },
    { field: "userAccess", renderHeader: () => <CustomHeaderAcceso />, width: 150,
     valueGetter: (param) => {
      const email = param.row.userAccess;
      const leftPart = email && email.indexOf('@') !== -1 ? email.substring(0, email.indexOf('@')) : param.row.userAccess;

      return leftPart;
     },
     renderCell: (param) => {
        <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>
          <span className="FontCelda">{param.value}</span>
        </div>
      },
    },
    { field: "email", renderHeader: () => <CustomHeaderCorreo />, width: 200 },
    { field: "proyectos", headerName: "Proyectos", width: 250, 
    valueGetter: (param) => {
         let proy = '';
         param.row.proyectos.map((proyecto) => (
         proy += findNomById(proyecto) + " "
         ))
      
      return proy;
    },
    renderCell: (param) => {
      return(
        <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: '12px', marginRight:'4px' }}>
          <span>{param.value}</span>
        </div>
      )
    }
    },
    { field: "comentarios", headerName: "comentarios", width: 200 },
    {
      field: "activo",
      headerName: "Activo",
      width: 75,
      align: "center",
      renderHeader: () => (
        <div style={{ textAlign: "center", color: "#333" }}>
          <b>Activo</b>
        </div>
      ),
      renderCell: (param) => {
        let icon = "";
        if (param.row.activo == true) {
          icon = <DoneAllIcon style={{ color: "green" }} />;
        } else {
          icon = <RemoveDoneIcon style={{ color: "red" }} />;
        }

        return <div style={{ textAlign: "center" }}>{icon}</div>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      disableButton,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatEdit index={params.row.id} />
          </div>
        );
      },
    },
  ];

  

  const MatEdit = ({ index }) => {
    const handleEditClick = async () => {
      setAction('edit');
      setrefreshTable(true);
      //Buscamos el usuario por su uid
      const docRef = doc(db, `usuarios`, `${index}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        
        const userAccess = docSnap.data()?.userAccess.split('@')[0];

        setDataEdit({
          id: docSnap.id,
          usuario: docSnap.data().usuario,
          correo: docSnap.data().correo,
          comentarios: docSnap.data().comentarios,
          activo: docSnap.data().activo,
          userAccess: userAccess,
          password: docSnap.data().firstPassword,
          empresa: docSnap.data().empresaID,
          proyectos: docSnap.data().proyectos,

        });
        const Arr = [];
        const ArrayProyectos = docSnap.data().proyectos;
        ArrayProyectos.map((proyecto)=>{
            Arr.push({ id: proyecto, nom: findNomById(proyecto) })
          setChipProyectos(Arr);
        })
        setChecked(docSnap.data().activo);
        setshowModalForm(true);
        setrefreshTable(false);
      } else {
        setMensaje({
          show: true,
          texto: `Ha ocurrido un problema de lectura!`,
          color: "error",
        });
      }
    };

    return (
      <FormControlLabel
        control={
          <IconButton
            color="secondary"
            aria-label="Editar..."
            onClick={handleEditClick}
            title="Editar usuario"
          >
            <Edit style={{ color: blue[500] }} />
          </IconButton>
        }
      />
    );
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const generateRandomPasswords = () => {
    const uid = generateRandomPassword(8);
    setValue("password", uid);
  };

  const HandleFormSubmit = async (data) => {
    const isValidPassword = schemaPassword.validate(data.password);
    const isValidUser = schemaUsuario.validate(data.userAccess);
    
    if (data.empresa == "0") {
      setMensaje({
        show: true,
        texto: `Selecciona una Empresa de la lista`,
        color: "error",
      });
      return;
    }
    
    setBackdrop(true);

    if (action === 'new') {
      
      if (!isValidUser) {
        setMensaje({
          show: true,
          texto: `El usuario no cuple con los requisitos mínimos.`,
          color: "error",
        });
        setBackdrop(false);
        return;
      }
  
      if (!isValidPassword) {
         setMensaje({
          show: true,
          texto: `La password no cumple con los requisitos mínimos.`,
          color: "error",
        });
        setBackdrop(false);
        return;
      }

          let proyectos = [];
          const datos = {
            activo: true,
            usuario: data.usuario.toUpperCase(),
            correo: data.correo,
            createdBY: user.uid,
            createdate: obtenerFechaYHoraActual(),
            proyectos: proyectos,
            empresaID: data.empresa,
            empresaNOM: "",
            isAdmin: false,
            isLocked: false,
            notificationToken: "",
            key: "",
            locked: false,
            userAccess: data.userAccess + '@tickme.com',
            firstPassword: data.password,
            comentarios: data.comentarios,
            rol: data.rol,
           // celular: data.celular,
          };

          chipProyectos.map((item)=> {
              proyectos.push(item.id)
          })

          //llama a la funcion para crear un usuario en users y crear con el mismo UID el usuario en usuarios.
          const email = data.userAccess + '@tickme.com';
          const password = data.password;
          const displayName = data.usuario.toUpperCase();
          const empresaID = data.empresa;
          const datas = { email, password, displayName, empresaID };

          await createUser(datas)
            .then(async (message) => {
              const recived = message.data;
              if (recived.success) {
                
                if (recived.message === 'step1')
                {
                  setMensaje({
                    show: true,
                    texto: `El usuario: "${data.userAccess}" ya existe, reintenta con otro diferente!`,
                    color: "warning",
                  });
                  setBackdrop(false);
                }
                
                if (recived.message === 'step2' || recived.message === 'step3' )
                {
                    //creamos usuario en usuarios con el uid recibido.
                    const userRef = collection(db, "usuarios");
                    const userDoc = doc(userRef, recived.id);
                    await setDoc(userDoc, datos)
                    .then(()=>{
                      setMensaje({
                        show: true,
                        texto: `Se ha creado el Usuario: ${data.usuario.toUpperCase()} con éxito!`,
                        color: "success",
                      });
                      setBackdrop(false);
                      setshowModalForm(false);
                      resetForms();
                      loadUsuarios();
                    })
                    .catch((error)=>{
                      console.error(error)
                      setMensaje({
                        show: true,
                        texto: `Error al crear el usuario: ${data.usuario.toUpperCase()}!`,
                        color: "error",
                      });
                      setBackdrop(false);
                    });
                }

              } 
            })
            .catch((error)=>{
              console.error(error)
              setMensaje({
                show: true,
                texto: `Error al crear el usuario: ${data.usuario.toUpperCase()}!`,
                color: "error",
              });
              setBackdrop(false);
            });
      
    } //action new

    if (action === 'edit') {

      const actRegistro = doc(db, `usuarios/${dataEdit.id}`);
      let proyectos = [];
      
      chipProyectos.map((item)=> {
        proyectos.push(item.id)
      })
      const update = await updateDoc(actRegistro, {
        usuario: data.usuario.toUpperCase(),
        correo: data.correo,
        comentarios: data.comentarios,
        proyectos: proyectos,
        activo: JSON.parse(checked),
        empresaID: data.empresa,
        rol: data.rol,
        celular: data.celular,
      })
        .then(function () {
          loadUsuarios();
          setMensaje({
            show: true,
            texto: `Se ha modificado el Usuario: ${data.usuario.toUpperCase()} con éxito!`,
            color: "success",
          });
          setshowModalForm(false);
          resetForms();
          setDataEdit(null);
          loadUsuarios();
        })
        .catch((error) => {
          console.error(error)
          setMensaje({
            show: true,
            texto: `Se ha producido un error al modificar el Usuario ${data.usuario}.`,
            color: "error",
          });
        });
        setBackdrop(false);
    } //action edit
  };

  const handleChangeChecked = (event) => {
    setChecked(!checked);
  };

  const resetForms = () => {
    reset({usuario: '', correo: '', userAccess: '', proyectos: '0', empresa: '0', comentarios: '', password: ''});
    setChipProyectos((chips) =>
      chips.filter((chip) => chip.id === obtenerUIDProyecto())
    );
  }

  const handleModalCreateClose = () => {
    setshowModalForm(false);
    resetForms();
  };

  const handleCloseDeleteModal = () => {
    setopenDeleteModal(false);
  };

  const deleteGrupos = async (agente) => {
    const q = query(
      collection(db, "grupos"),
      where("proyectoID", "==", obtenerUIDProyecto()),
      where("agentes", "array-contains", agente)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      const docRef = doc.ref;
      const label = [];
      label.push(`Eliminando AGENTE de (${querySnapshot.size}) grupo(s)...`);
      setdeletelabel(label);
      await updateDoc(docRef, { agentes: arrayRemove(agente) });
    });
  };

  const deleteUsuarios = async (agente) => {
    const docRef = doc(db, "usuarios", agente);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      if (data.proyectos.length > 1) {
        await updateDoc(docRef, {
          proyectos: arrayRemove(obtenerUIDProyecto()),
        });
      } else {
        await updateDoc(docRef, { activo: false });
      }
    }
  };

  const HandleDeleteSelected = async () => {
    setrefreshTable(true);
    selectionModel.map(async (agente) => {
      const deleteAgentesSeleccionado = doc(db, "agentes", agente);
      await deleteDoc(deleteAgentesSeleccionado);
      setdeletelabel("Verificando dependencias en GRUPOS...");
      await deleteGrupos(agente);
      await deleteUsuarios(agente);
    });

    setMensaje({
      show: true,
      texto: `Se ha eliminado el agente(s) seleccionado(s) con éxito!`,
      color: "success",
    });
    loadUsuarios();
    //  setopenDeleteModal(false);
  };

  const deleteChipProyecto = (chipToDelete) => {
    setChipProyectos((chips) =>
      chips.filter((chip) => chip.id !== chipToDelete.id)
    );
  };

  const handleProyectoChange = (event) => {
    const foundObject = chipProyectos.find(
      (item) => item.id === event.target.value
    );
    const selectElement = document.getElementById("proyectos");
    const selectedOption = selectElement.selectedOptions[0].innerText;

    if (!foundObject) {
      const newObject = { id: event.target.value, nom: selectedOption };
      setChipProyectos((prevState) => [...prevState, newObject]);
    }
  };

  function CustomToolbar() {
    return (
      <>
      <Row>
        <Col md={7}>
      <GridToolbarContainer sx={{paddingLeft: '12px' }}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
      </Col>
      <Col md={5} style={{textAlign: 'right', }}>
        <GridToolbarQuickFilter sx={{ paddingRight: '10px'  }} />
      </Col>
      </Row>
      </>
    );
  }


  return (
    <>
      <div className="col-12 col-sm-12 col-md-12">
        <div className="card card-primary card-outline">
          <div className="card-header">
            <h3 className="card-title" style={{ paddingTop: "8px" }}>
              Usuarios del proyecto{" "}<RefreshIcon sx={{cursor:'pointer', color: 'gray'}} titleAccess="Recargar lista" onClick={loadUsuarios} />
            </h3>
            <div className="card-tools">
              <div className="btn-group">
                {selectionModel.length > 0 && (
                  <>
                    <button
                      type="button"
                      className="btn btn-tool"
                      title="copiar Usuarios seleccionados a otros Proyectos."
                     // onClick={alert("propiedad no Habilitada")}
                    >
                      <Badge
                        badgeContent={selectionModel.length}
                        sx={{ color: blue[500] }}
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      >
                        <CopyAllIcon />
                      </Badge>
                    </button>

                    <button
                      type="button"
                      className="btn btn-tool"
                      title="Eliminar Usuarios seleccionados."
                      onClick={() => setopenDeleteModal(true)}
                    >
                      <Badge
                        badgeContent={selectionModel.length}
                        sx={{ color: pink[500] }}
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      >
                        <DeleteIcon />
                      </Badge>
                    </button>
                  </>
                )}

                <div>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    Opciones
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setAction('new');
                        setshowModalForm(true);
                      }}
                    >
                      <AddCircleOutlineOutlined sx={{ color: blue[500] }} />
                      <span className="text-sm pl-2"> Nuevo Usuario </span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setopenDeleteModal(true);
                      }}
                      disabled={selectionModel.length < 1}
                    >
                      <DeleteIcon sx={{ color: pink[500] }} />{" "}
                      <span className="text-sm pl-2">
                        Eliminar ({selectionModel.length}) seleccionados
                      </span>
                    </MenuItem>
                  </Menu>
                </div>
              </div>

              <button
                type="button"
                className="btn btn-tool"
                data-card-widget="maximize"
              >
                <i className="fas fa-expand"></i>
              </button>
            </div>
          </div>
          <div style={{ height: "auto", width: "100%" }}>
            <DataGrid
              sx={{fontSize: '13px'}}
              
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
                columns: { columnVisibilityModel: { id: false, comentarios: false } },
              }}
              disableRowSelectionOnClick
              slots={{
                //  columnMenu: MyCustomColumnMenu,
                  toolbar: CustomToolbar,
              }}
              slotProps={{
                //  columnMenu: { background: 'red', counter: rows },
                  pagination: { labelRowsPerPage: '' }
              }}
              columns={columns}
              rows={data}
              pageSizeOptions={[25]}
              autoHeight
              components={{
                noRowsLabel: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No se han encontrado usuarios
                  </Stack>
                ),
                noResultsOverlayLabel: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No se han encontrado coincidencias
                  </Stack>
                ),
              }}
              localeText={localeText}
              rowsPerPageOptions={[5]}
              checkboxSelection
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              loading={refreshTable}
              selectionModel={selectionModel}
            />
          </div>
        </div>
      </div>

      <div>
        <Modal
          show={showModalForm}
          onHide={handleModalCreateClose}
          size="lg"
          backdrop="static"
        >
          <Form onSubmit={handleSubmit(HandleFormSubmit)}>
            <Modal.Header>
              <Modal.Title>
                {action === 'new' ? 'Crear nuevo usuario' : 'Editar usuario'}
                <span className="muted text-md ml-2">
                  (Proyecto: {obtenerNOMProyecto()})
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col Col xs="12" lg="6">
                  <Form.Group
                    className="mb-4"
                  >
                    <Form.Label className="labelForms">
                      Usuario de acceso*
                      <span>
                        <FormControlLabel className="switch"
                        control={<Switch size="small" color="success"  
                        //checked={action === 'edit' ? dataEdit?.activo : false}
                        value={checked}
                        checked={checked}
                        onChange={handleChangeChecked}
                        onClick={handleChangeChecked}
                        inputProps={{ 'aria-label': 'controlled' }}
                        {...register("activo")}
                        />} label={checked ? 'Activo' : 'Inactivo'} />
                      </span>
                    </Form.Label>
                    <InputGroup className="mb-0">
                      <Form.Control
                        placeholder="username"
                        type="text"
                        defaultValue={action === 'edit' ? dataEdit?.userAccess : ''}
                        readOnly={action === 'edit'}
                        autoFocus
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        style={{ borderRight: "0px" }}
                        {...register("userAccess", {
                          minLength: 5,
                          required: true,
                        })}
                      />
                      <InputGroup.Text
                        style={{
                          borderBottomLeftRadius: "0px",
                          borderTopLeftRadius: "0px",
                        }}
                      >
                        @tickme.com
                      </InputGroup.Text>
                    </InputGroup>
                    {errors.userAccess?.type === "required" && (
                      <span className="text-xs pl-1 text-danger">
                        El usuario es obligatorio.
                      </span>
                    )}
                    {errors.userAccess?.type === "minLength" && (
                      <span className="text-xs pl-1 text-danger">
                        El nombre es muy corto (mínimo 5 caractéres).
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col Col xs="12" lg="6">
                  <Form.Group
                    className="mb-2"
                  >
                    <Form.Label className="labelForms">
                      Nombre completo*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={action === 'edit' ? dataEdit?.usuario : ''}
                      placeholder="Display name"
                      autoFocus
                      {...register("usuario", {
                        minLength: 5,
                        required: true,
                      })}
                      autoComplete="off"
                    />
                    {errors.usuario?.type === "required" && (
                      <span className="text-xs pl-1 text-danger">
                        El nombre es obligatorio.
                      </span>
                    )}
                    {errors.usuario?.type === "minLength" && (
                      <span className="text-xs pl-1 text-danger">
                        El nombre es muy corto (mínimo 5 caractéres).
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col xs="12" lg="6">
                  <Form.Group
                    className="mb-4"
                  >
                    <Form.Label className="labelForms">password*</Form.Label>
                    <InputGroup className="mb-0">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        defaultValue={action === 'edit' ? dataEdit?.password : ''}
                        placeholder="Password de acceso"
                        {...register("password", {
                          minLength: 6,
                          required: true,
                        })}
                        autoComplete="off"
                      />
                      <InputGroup.Text
                        onClick={generateRandomPasswords}
                        style={{
                          cursor: "pointer",
                          borderRadius: "0px",
                        }}
                      >
                        <PasswordIcon titleAccess="Generar password aleatorea" />
                      </InputGroup.Text>
                      <InputGroup.Text
                        onClick={togglePasswordVisibility}
                        style={{
                          cursor: "pointer",
                          borderBottomLeftRadius: "0px",
                          borderTopLeftRadius: "0px",
                          borderLeft: "0px",
                        }}
                      >
                        {showPassword ? (
                          <VisibilityIcon titleAccess="Ocultar password" />
                        ) : (
                          <VisibilityOffIcon titleAccess="Mostrar password" />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                    {errors.password?.type === "required" && (
                      <span className="text-xs pl-1 text-danger">
                        La password es obligatoria.
                      </span>
                    )}
                    {errors.password?.type === "minLength" && (
                      <span className="text-xs pl-1 text-danger">
                        La password es demasiado corta.
                      </span>
                    )}
                    <Form.Text muted>
                      La password debe ser de 6-20 caractéres de longitud,
                      contener letras y números, mayúsculas y minúsculas y no debe contener espacios o
                      emojis.
                    </Form.Text>
                  </Form.Group>

                  <Form.Label className="labelForms">Empresa*</Form.Label>
                  <InputGroup className="mb-3">
                    <select
                      className="form-control"
                      {...register("empresa")}
                      required
                      defaultValue={action === 'edit' ? dataEdit?.empresaID : '0'}
                    >
                      {clientes.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.nom}
                        </option>
                      ))}
                    </select>
                    <InputGroup.Text
                      onClick={() => loadClientes()}
                      style={{
                        cursor: "pointer",
                        borderBottomLeftRadius: "0px",
                        borderTopLeftRadius: "0px",
                        borderLeft: "0px",
                      }}
                    >
                      <RefreshIcon titleAccess="Actualizar lista de Empresas" />
                    </InputGroup.Text>
                  </InputGroup>

                  <Form.Label className="labelForms">Perfil de usuario*</Form.Label>
                  <InputGroup className="mb-3">
                    <select
                      className="form-control"
                      {...register("rol")}
                      required
                      defaultValue={action === 'edit' ? dataEdit?.rol : '0'}
                    >
                      <option value="0">Selecciona perfil...</option>
                      <option value="agente">Agente</option>
                      <option value="cliente">Cliente</option>
                    </select>
                    </InputGroup>
                </Col>
                <Col xs="12" lg="6">
                  <Form.Group
                    className="mb-4"
                  >
                    <Form.Label className="labelForms">
                      Email de contacto*
                    </Form.Label>
                    <Form.Control
                      type="email"
                      defaultValue={action === 'edit' ? dataEdit?.correo : ''}
                      placeholder="@email para notificaciones"
                      {...register("correo", {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                      autoComplete="off"
                    />
                    {errors.correo?.type === "pattern" && (
                      <span className="text-xs pl-1 text-danger">
                        Formato de correo no válido.
                      </span>
                    )}
                    {errors.correo?.type === "required" && (
                      <span className="text-xs pl-1 text-danger">
                        El correo es obligatorio.
                      </span>
                    )}
                  </Form.Group>
                  <Form.Label className="labelForms">Proyectos</Form.Label>
                  <InputGroup className="mb-3">
                    <select
                      id="proyectos"
                      className="form-control"
                      {...register("proyectos")}
                      onChange={handleProyectoChange}
                    >
                      {proyectos.map((option, index) => (
                        <option
                          key={index}
                          value={option.id}
                          disabled={chipProyectos.find(
                            (item) => item.id === option.id || option.id == '0'
                          )}
                        >
                          {option.nom}
                        </option>
                      ))}
                    </select>
                    <InputGroup.Text
                      onClick={() => loadProyectos()}
                      style={{
                        cursor: "pointer",
                        borderBottomLeftRadius: "0px",
                        borderTopLeftRadius: "0px",
                        borderLeft: "0px",
                      }}
                    >
                      <RefreshIcon titleAccess="Actualizar lista de Proyectos" />
                    </InputGroup.Text>
                  </InputGroup>
                  <Form.Label className="labelForms">
                    Proyectos seleccionados:
                  </Form.Label>
                  <div>
                    {chipProyectos.map((item) => {
                      return (
                        <Chip
                          label={item?.nom}
                          key={item?.id}
                          onDelete={() =>
                            item.id === obtenerUIDProyecto()
                              ? undefined
                              : deleteChipProyecto(item)
                          }
                          sx={{ marginRight: "5px", marginBottom: "5px" }}
                          color={
                            item.id === obtenerUIDProyecto()
                              ? "info"
                              : "success"
                          }
                          variant="outlined"
                          deleteIcon={
                            item.id === obtenerUIDProyecto() && <CheckIcon />
                          }
                        />
                      );
                    })}
                  </div>
                </Col>
              </Row>
              <Col xs="12" className="mt-2">
                <Form.Group
                  className="mb-3"
                >
                  <Form.Label className="labelForms">Comentarios</Form.Label>
                  <Form.Control
                    as="textarea"
                    defaultValue={action === 'edit' ? dataEdit?.comentarios : ''}
                    rows={2}
                    {...register("comentarios", {})}
                  />
                </Form.Group>
              </Col>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleModalCreateClose} sx={{ color: "gray" }}>
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="info"
                disableElevation
              >
                {action === 'edit' ? 'Actualizar' : 'Crear'}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Dialog
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">{"Borra los datos"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Alert severity="error">
                <span style={{ fontWeight: "bold", color: "#d32f2f" }}>
                  Esta acción borrará de manera permanente todos los datos de la
                  ubicación, incluidos los datos anidados
                </span>
              </Alert>
              <p style={{ marginTop: "30px", fontSize: "14px" }}>
                Se borrará el registro de todos los grupos, tickets y acceso
                asociados al mismo del proyecto actual.
              </p>
              <p style={{ fontSize: "14px", color: "gray" }}>- {deletelabel}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ margin: "10px" }}>
            <Button
              onClick={() => handleCloseDeleteModal()}
              sx={{ color: "gray" }}
            >
              Cancelar
            </Button>
            <Button
              onClick={HandleDeleteSelected}
              autoFocus
              variant="contained"
              color="error"
              disableElevation
            >
              Comenzar a borrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ListUsuarios;
