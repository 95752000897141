import React, { useState, useEffect, useRef } from "react";
import ContentHeader from "../../components/ContentHeader";
import { json, useParams } from "react-router-dom";
import axios from "axios";
//import Select from "react-select";
import { meses } from "../../components/functions";
import { useForm } from "react-hook-form";


const CloneInvoice = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const [clientes, setclientes] = useState([{}]);
  const [selClientes,  setSelCliente] = useState();
  const [filtraServicios, setFiltraServicios] = useState([]);
   
  const params = useParams();
  const RefForm = useRef();

  const LoadPad = async () => {
    setloading(true);
    var f = new FormData();
    f.append("Idpad", params.Pad);

    await axios
      .post("https://itcglobal.mx/mobile/LoadInvoiceWithPad.php", f)
      .then((response) => {
        setData(response.data);
        console.log(response.data);
        setloading(false);
        // const ClienteSeleccionado = clientes.filter(cliente=> cliente.value == data.tCliente);
       })
      .catch((error) => {
        alert(error);
      });
  };

  const LoadCliente = async () => {
    await axios
      .get("https://itcglobal.mx/mobile/CargaEmpresas.php")
      .then((response) => {
        setclientes(response.data);
       
       })
      .catch((error) => {
        alert(error);
      });
  };

  const FiltraServicios = async (IdCliente) => {
    var f = new FormData();
    f.append("cliente", IdCliente);
    setData({...data, tCliente: IdCliente});

    await axios
      .post("https://itcglobal.mx/mobile/FiltraServicios.php",f)
      .then((response) => {
       setFiltraServicios(response.data.datos);
       
      })
      .catch((error) => {
        alert(error);
      });
  };

     function handleChange(event) {
        const { name, value } = event.target;

        if (name === 'tImpNeto') {
            let Neto = parseFloat(value).toFixed(2);
			let Total = Neto*1.16;
			let Iva = Total-Neto;
            setData({ ...data, tImpNeto: value,tImpTotal: Total.toFixed(2), tIva: Iva.toFixed(2) });
            return;
        }

        setData({ ...data, [name]: value });
        
        }

        function sendForm() {

            var formData = new FormData();
          //  formData.append("page", page);
        }

  useEffect(() => {
    LoadPad();
    LoadCliente();
    FiltraServicios(params.Id);
    
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    var f = new FormData();
    f.append("factura", data.tFactura );
    f.append("cliente", data.tCliente );
    f.append("servicio", data.tServicio );
    f.append("impNeto", data.tImpNeto );
    f.append("impIva", data.tIva );
    f.append("imptotal", data.tImpTotal );
    f.append("fecFactura", data.tFecFac);
    f.append("fecFacEnv", data.FecEnv);
    f.append("tMesServ", data.tMesServ);
    f.append("tText",data.tText);
    
    await axios
    .post("https://itcglobal.mx/mobile/CloneInvoice.php",f)
    .then((response) => {
     setloading(false);
     alert(response.tag)
    })
    .catch((error) => {
      alert(error);
    });
    
  }

  return (
    <>
      <ContentHeader title="Clonar Factura" back="Facturación" nav="invoices" />

      <div className="card card-primary card-outline">
        <div className="card-header">
          <div className="row">
            <div className="col-xs-6 col-sm-3 col-md-6 card-title">
              <h6>Rellena los datos del Formulario</h6>
            </div>
            <div className="col-xs-6 col-sm-2 col-md-2 ml-auto text-right ">
              <button
                type="submit"
                formTarget="Formclone"
                className="btn btn-primary btn-sm"
                title="Dar de Alta"
                onClick={onSubmit}
              >
                <i className="fa fa-plus-circle" /> Crear
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <form name="Formclone" id="Formclone" ref={RefForm}>
            <button type="submit" value="Crear"/>
            <div className="row">
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group text-danger">
                  <label className="text-sm">N° de Factura</label>
                  <input
                    className="form-control border-danger"
                    type="text"
                    name='tFactura'
                    value= {data.tFactura}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label className="text-sm">Cliente</label>

                  <select
                    className="form-control  select2 select2-hidden-accessible"
                    style={{ width: "100%" }}
                    defaultValue={-1}
                    onChange={e => FiltraServicios(e.target.value)} 
                    name="tCliente"
                   
                  >
                    {clientes.map((option) => (
                      <option key={option.value} value={option.value}  selected={data.tCliente === option.value ? 'selected' : ''}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label className="text-sm">Servicio <i class="fas fa-external-link-square-alt" style={{ cursor:'pointer'}} title='Quick Servicio'></i></label>
                  <select
                    className="form-control  select2 select2-hidden-accessible"
                    name="tServicio"
                    style={{ width: "100%" }}
                    onChange={handleChange}
                  >
                       {filtraServicios.map((option) => (
                      <option key={option.t9id} value={option.t9id }  selected={data.tServicio === option.t9id ? 'selected' : ''}>
                        {option.t9nom}
                      </option>
                    ))}  
                  </select>
                </div>
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group">
                  <label className="text-sm">Órden de Compra</label>
                  <input className="form-control" type={"text"} name="po" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group">
                  <label className="text-sm">Importe Neto</label>
                  <input
                    className="form-control"
                    type={"text"}
                    value={data.tImpNeto}
                    id="tImpNeto"
                    name="tImpNeto"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group">
                  <label className="text-sm">Importe IVA</label>
                  <input
                    className="form-control"
                    type={"text"}
                    value={data.tIva}
                    id="tIva"
                    name="tIva"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group">
                  <label className="text-sm">Importe Total</label>
                  <input
                    className="form-control"
                    type={"text"}
                    value={data.tImpTotal}
                    id="tImpTotal"
                    name="tImpTotal"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 text-danger">
                <div className="form-group">
                  <label className="text-sm">Fecha Factura</label>
                  <input className="form-control border-danger" type={"date"} name="tFecFac" onChange={handleChange} />
                </div>
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 text-danger">
                <div className="form-group">
                  <label className="text-sm">Fecha Envío</label>
                  <input className="form-control border-danger" type={"date"} name="FecEnv" onChange={handleChange} />
                </div>
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group">
                  <label className="text-sm text-danger">Servicio</label>
                  <select
                    className="form-control select2"
                    id="tMesServ"
                    name="tMesServ"
                    style={{ width: "100%" }}
                    onChange={handleChange}
                  >
                  {meses.map((option) => (
                      <option key={option.value} value={option.value}  selected={data.tMesServ === option.value ? 'selected' : ''}>
                        {option.mes}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-2 col-md-2">
                <div className="form-group">
                  <label className="text-sm">N°Cotización</label>
                  <select
                    className="form-control select2"
                    id="tPO"
                    name="tPO"
                    style={{ width: "100%" }}
                    defaultValue={-1}
                  ></select>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                aria-rowspan={3}
              >
                <div className="form-group">
                  <label className="text-sm">Comentarios</label>
                  <textarea
                    rows={3}
                    className="form-control"
                    value={data.tText}
                    onChange={handleChange}
                    name="tText"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        {loading ? (
          <div class="overlay">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CloneInvoice;
