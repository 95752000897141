import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { auth, db } from "../../firebase";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
  where, query, orderBy
} from "firebase/firestore";
import { useAuth } from "../../contexts/authcontext";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Select } from "@mui/material";
import "@fontsource/roboto/300.css";
import { obtenerFechaYHoraActual } from "../functions";
import { useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import Badge from "@mui/material/Badge";
import { FormControlLabel, IconButton } from "@mui/material";
import { blue, pink, grey } from "@mui/material/colors";
import {
  BorderAllOutlined,
  Edit,
  LineWeight,
  VerticalAlignCenter,
} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { Stack } from "@mui/system";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { obtenerUIDProyecto, obtenerNOMProyecto } from "../functions";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ListGrupos = () => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [data, setData] = useState([]);
  const [agentes, setAgentes] = useState([]);
  const [showModalCreate, setshowModalCreate] = useState(false);
  const [showModalEdit, setshowModalEdit] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [refreshTable, setrefreshTable] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);

  const { selproyecto, user, setNumGrupos, setMensaje } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
   // defaultValues: dataEdit,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    listgrupos();
    loadAgentes();
  }, [selproyecto]);

  useEffect(() => {
    reset(dataEdit);
    reset();
  }, [dataEdit, reset]);

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const { target: { value }, } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  function findNomById(myText) {
    const foundObject = agentes.find((obj) => obj.id === myText);
    const foundName = foundObject ? foundObject.nom : "";
    return foundName;
  }

  const listgrupos = async () => {
    setrefreshTable(true);
    const q = query(collection(db, 'grupos'), where("proyectoID", "==", obtenerUIDProyecto()), orderBy("nombre", "asc"));
    const docSnap = await getDocs(q)
    .then((querySnapshot)=>{
      const datos = [];
      querySnapshot.forEach((doc) => {
        datos.push({
          id: doc.id,
          nombre: doc.data().nombre,
          comentarios: doc.data().comentarios,
          agentes: doc.data().agentes,
          activo: doc.data().activo === true ? true : false,
        });
      })
      setData(datos);
      setNumGrupos(datos.length);
      setrefreshTable(false);
    })
    .catch((error)=>{
      setMensaje({show: true, texto: `Ha ocurrido un problema de lectura! Reintente más tarde.` , color: 'danger'});
    })
  };

  const loadAgentes = async () => {
    const q = query(collection(db, 'agentes'), where("proyectoID", "==", obtenerUIDProyecto()), orderBy("nombre", "asc"));
    const querySnapshot = await getDocs(q);
  
    const datos = [];
    querySnapshot.forEach((doc) => {
      datos.push({
        id: doc.id,
        nom: doc.data().nombre,
      });
    });
    setAgentes(datos);
     
  };

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "nombre", headerName: "Grupo", width: 200 },
    { field: "comentarios", headerName: "Comentarios", width: 250 },
    { field: "activo", headerName: "Activo", width: 75, align: "center", 
      renderHeader: () => <div style={{ textAlign: 'center', color: "#333" }}><b>Activo</b></div>,
      renderCell: (param) => {
        let icon = "";
        if (param.row.activo == true) {icon = <DoneAllIcon style={{ color: "green"}}/>} else  {icon = <RemoveDoneIcon style={{ color: "red"}}/>}
        
        return (
          <div style={{ textAlign: 'center' }}>{icon}</div>
        )
      }
    },
    {
      field: "agentes",
      headerName: "Agentes",
      width: 350,
      renderCell: (params) => {
        
        return(
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {
                
              params.row.agentes.map((agente) => (
                <Chip key={agente} label={findNomById(agente)} color="success" variant="outlined" size="small" sx={{ fontSize:"8px"}}/>
              ))
          }
          </Box>
        )
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      disableButton,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatEdit index={params.row.id} />
          </div>
        );
      },
    },
  ];

  const MatEdit = ({ index }) => {
    const handleEditClick = async () => {
      setrefreshTable(true);
      //Buscamos el registro por su uid
      const docRef = doc(db,'grupos',`${index}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setDataEdit({
          id: docSnap.id,
          nombre: docSnap.data().nombre,
          comentarios: docSnap.data().comentarios,
          agentes: docSnap.data().agentes,
          activo: docSnap.data().activo
        });
        setPersonName(docSnap.data().agentes);
        setshowModalEdit(true);
        setrefreshTable(false);
      } else {
        setMensaje({show: true, texto: `Ha ocurrido un problema al visualizar el Grupo!` , color: 'danger'})
      }
    };

    return (
      <FormControlLabel
        control={
          <IconButton
            color="secondary"
            aria-label="Editar..."
            onClick={handleEditClick}
            title="Editar Grupo"
          >
            <Edit style={{ color: blue[500] }} />
          </IconButton>
        }
      />
    );
  };

  const resetForm = () => {
    reset({nombre:"", descripcion:""})
  }

  const HandleCreateGrupoSubmit = async (data) => {
    setDisableButton(true);
    const createGrupo = await addDoc(collection(db,`grupos`),
      {
        activo: true,
        nombre: data.nombre.toUpperCase(),
        proyectoID: obtenerUIDProyecto(),
        createdBY: user.uid,
        comentarios: data.descripcion,
        createdate: obtenerFechaYHoraActual(),
        agentes: personName
      }
    ).then(() => {
      setMensaje({show: true, texto: `Se ha creado el Grupo: ${data.nombre.toUpperCase()} con éxito!` , color: 'success'});
      setPersonName([]);
      setshowModalCreate(false);
      setDisableButton(false);
      listgrupos();
      resetForm();
    })
    .catch(()=>{
      setMensaje({show: true, texto: `Ha ocurrido un problema de lectura!. Reintente más tarde.` , color: 'danger'});
      setDisableButton(false);
    })
  };

  const HandleEditGrupoSubmit = async (data) => {
    //setDisableButton(true);
    const actgrupo = doc(db,`grupos/${dataEdit.id}`);

    const update = await updateDoc(actgrupo, {
      nombre: data.nombre.toUpperCase(),
      comentarios: data.comentarios,
      agentes: personName,
      activo: JSON.parse(data.activo)
      
    }).then(function () {
      setshowModalEdit(false);
      listgrupos();
      setPersonName([]);
      resetForm();
      setMensaje({show: true, texto: `Se ha modificado el Grupo: ${data.nombre.toUpperCase()} con éxito.` , color: 'success'});
    })
    .catch(()=>{
      setMensaje({show: true, texto: `Se produjo un error al modificar el grupo: ${data.nombre.toUpperCase()} reintenta más tarde.` , color: 'danger'});
      setshowModalEdit(false);
    })
  };

  const handleModalCreateClose = () => {
    setshowModalCreate(false);
    setPersonName([]);
    resetForm();
  };

  const handleModalEditClose = () => {
    setshowModalEdit(false);
    setPersonName([]);
    resetForm();
  };

  const handleCloseDeleteModal = () => {
    setopenDeleteModal(false);
  };

  const HandleDeleteSelected = async () => {
    setrefreshTable(true);
    selectionModel.map((grupo) => {
      const deleteClientesSeleccionado = doc(db,"grupos",grupo);
      deleteDoc(deleteClientesSeleccionado);
    });

    listgrupos();
    setopenDeleteModal(false);
    setMensaje({show: true, texto: `Se ha eliminado el grupo(s) seleccionado(s) con éxito!` , color: 'success'});
  };

  return (
    <>
      <div className="col-12 col-sm-12 col-md-12">
        <div className="card card-primary card-outline">
          <div className="card-header">
            <h3 className="card-title" style={{marginTop: '8px'}}>Grupos </h3>
            <div className="card-tools">
              <div className="btn-group">
                {selectionModel.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-tool"
                    title="Eliminar Grupos seleccionados."
                    onClick={() => setopenDeleteModal(true)}
                  >
                    <Badge
                      badgeContent={selectionModel.length}
                      sx={{ color: pink[500] }}
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                      <DeleteIcon />
                    </Badge>
                  </button>
                )}

                <div>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    Opciones
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setshowModalCreate(true);
                      }}
                    >
                      <AddCircleOutlineOutlined sx={{ color: blue[500] }} />
                      <span className="text-sm pl-2"> Nuevo grupo</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setopenDeleteModal(true);
                      }}
                      disabled={selectionModel.length < 1}
                    >
                      <DeleteIcon sx={{ color: pink[500] }} />{" "}
                      <span className="text-sm pl-2">
                        Eliminar ({selectionModel.length}) seleccionados
                      </span>
                    </MenuItem>
                  </Menu>
                </div>
              </div>

              <button
                type="button"
                className="btn btn-tool"
                data-card-widget="maximize"
              >
                <i className="fas fa-expand"></i>
              </button>
            </div>
          </div>
          <div style={{ height: 'auto', width: "100%" }}>
          <DataGrid
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
                
                columns: { columnVisibilityModel: { id: false } },
              }}
              disableRowSelectionOnClick
              columns={columns}
              rows={data}
              pageSizeOptions={[5, 10, 25, 50]}
              autoHeight
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No existen grupos creados
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No se han encontrado coincidencias
                  </Stack>
                ),
              }}
              localeText={{
                columnsPanelShowAllButton: "Show All",
                columnsPanelTextFieldLabel: "Buscar columna",
                columnMenuFilter: "Filtrar",
                columnMenuHideColumn: "Ocultar",
                columnMenuShowColumns: "Columnas...",
                footerRowSelected: (count) =>
                  count !== 1
                    ? `${count.toLocaleString()} registro(s) seleccionado(s)`
                    : `${count.toLocaleString()} registro seleccionado`,
              }}
              rowsPerPageOptions={[5]}
              checkboxSelection
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              loading={refreshTable}
              selectionModel={selectionModel}
            />
          </div>
        </div>
      </div>

      <div>
        <Modal show={showModalCreate} onHide={handleModalCreateClose} size="lg">
          <Form
            id="createCliente"
            onSubmit={handleSubmit(HandleCreateGrupoSubmit)}
          >
            <Modal.Header>
              <Modal.Title>
                Crear nuevo grupo{" "}
                <span className="muted text-md">
                  (Proy: {obtenerNOMProyecto()})
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel
                      sx={{
                        backgroundColor: "#fff",
                        paddingRight: "5px",
                      //  typography: { fontSize: "14px", fontVariant: "none" },
                      }}
                    >
                      Nombre del grupo
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      label="Name"
                      autoFocus
                      name="nombre"
                      {...register("nombre", {
                        minLength: 3,
                        required: true,
                        autoFocus: true,
                        value: ""
                      })}
                    />
                    {errors.nombre?.type === "required" && (
                      <span className="text-xs pl-1 text-danger">
                        El nombre es obligatorio.
                      </span>
                    )}
                    {errors.nombre?.type === "minLength" && (
                      <span className="text-xs pl-1 text-danger">
                        El nombre es muy corto.
                      </span>
                    )}
                  </FormControl>
                </Col>
                <Col className="mr-5">
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel
                      sx={{
                        backgroundColor: "#fff",
                        paddingRight: "5px",
                      }}
                    >
                      Miembros del grupo{" "}
                    </InputLabel>
                    <Select
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={findNomById(value)} color="primary" variant="outlined" />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {agentes.map((agente) => (
                        <MenuItem
                          key={agente?.id}
                          value={agente?.id}
                          style={getStyles(agente, personName, theme)}
                        >
                          {agente?.nom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col className="mt-2">
                  <FormControl sx={{ m: 1, width: "94%" }}>
                    <InputLabel
                      sx={{
                        backgroundColor: "#fff",
                        paddingRight: "5px",
                        typography: { fontSize: "14px", fontVariant: "none" },
                      }}
                    ></InputLabel>
                    <TextField
                      id="outlined-textarea"
                      label="Comentarios"
                      multiline
                      rows={3}
                      name="descripcion"                      
                      {...register("descripcion",{value:""})}
                    />
                    
                  </FormControl>
                </Col>
              </Row>
              <Col className="mt-2"></Col>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" onClick={handleModalCreateClose}>
                Cancelar
              </Button>
              <Button type="submit" disabled={disableButton}>
                Crear
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal show={showModalEdit} onHide={handleModalEditClose} size="lg">
          <Form
            id="editCliente"
            onSubmit={handleSubmit(HandleEditGrupoSubmit)}
          >
            <Modal.Header>
              <Modal.Title>
                Editar Grupo{" "}
                <span className="muted text-md">
                  (Proy: {obtenerNOMProyecto()})
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Row>
                <Col>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel
                      sx={{
                        backgroundColor: "#fff",
                        paddingRight: "5px",
                      //  typography: { fontSize: "14px", fontVariant: "none" },
                      }}
                    >
                      Nombre del grupo
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      label="Name"
                      autoFocus
                      name="nombre"
                      //value=""
                     // defaultValue={dataEdit?.nombre}
                      {...register("nombre", {
                        minLength: 3,
                        required: true,
                        autoFocus: true,
                        defaultValue: dataEdit?.nombre 
                      })}
                    />
                    {errors.nombre?.type === "required" && (
                      <span className="text-xs pl-1 text-danger">
                        El nombre es obligatorio.
                      </span>
                    )}
                    {errors.nombre?.type === "minLength" && (
                      <span className="text-xs pl-1 text-danger">
                        El nombre es muy corto.
                      </span>
                    )}
                  </FormControl>
                </Col>
                <Col className="mr-5">
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel
                      sx={{
                        backgroundColor: "#fff",
                        paddingRight: "5px",
                      }}
                    >
                      Selecciona miembros{" "}
                    </InputLabel>
                    <Select
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={findNomById(value)} color="primary" variant="outlined" />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {agentes.map((agente) => (
                        <MenuItem
                          key={agente?.id}
                          value={agente?.id}
                          style={getStyles(agente, personName, theme)}
                        >
                          {agente?.nom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row className="mb-3 ml-1">
                <Col xs lg="2">
                <Form.Label>Estado</Form.Label>
                    <select className="form-control" {...register("activo")}>
                        <option value={true}>Activo</option>
                        <option value={false}>Inactivo</option>
                    </select>
                </Col>
              </Row>
              <Row>
                <Col className="mt-2">
                  <FormControl sx={{ m: 1, width: "94%" }}>
                    <InputLabel
                      sx={{
                        backgroundColor: "#fff",
                        paddingRight: "5px",
                        typography: { fontSize: "14px", fontVariant: "none" },
                      }}
                    ></InputLabel>
                    <TextField
                      id="outlined-textarea"
                      label="Comentarios"
                      defaultValue={dataEdit?.comentarios}
                      multiline
                      rows={3}
                      name="descripcion"
                      {...register("comentarios")}
                    />
                    
                  </FormControl>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" onClick={handleModalEditClose}>
                Cancelar
              </Button>
              <Button type="submit">Guardar</Button>
            </Modal.Footer>
          </Form>
        </Modal>

       {/*  <Modal
          show={openDeleteModal}
          onHide={handleCloseDeleteModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirma para eliminar...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>Advertencia:</b> Al eliminar un grupo se eliminarán todos los
            datos asociados al mismo, como usuarios, contactos...
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => handleCloseDeleteModal()}>Cancelar</Button>
            <Button sx={{ color: pink[500] }} onClick={HandleDeleteSelected}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal> */}

        <Dialog
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
              {"Borra los datos"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Alert severity="error"><span style={{fontWeight:'bold', color: '#d32f2f'}}>Esta acción borrará de manera permanente todos los datos de la ubicación, incluidos los datos anidados</span></Alert>
                <p style={{ marginTop: '30px', fontSize:'14px'}}>Se borrará el registro de todos los grupos, tickets y acceso asociados al mismo del proyecto actual.</p>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{margin:'10px'}}>
              <Button onClick={() => handleCloseDeleteModal()} sx={{color:'gray'}}>Cancelar</Button>
              <Button onClick={HandleDeleteSelected} autoFocus variant="contained" color="error" disableElevation>
                Comenzar a borrar
              </Button>
            </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ListGrupos;
