import React from "react";

const WidgetUsersAdmin = () => {
  return (
    <>
      <div className="col-md-3 col-sm-6 col-12 mt-2">
        <div className="info-box">
          <span className="info-box-icon bg-info">
            <i className="far fa-envelope" />
          </span>
          <div className="info-box-content">
            <span className="info-box-text">Usuarios</span>
            <span className="info-box-number">1,410</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default WidgetUsersAdmin;
