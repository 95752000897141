import React from "react";
import { useAuth } from "../contexts/authcontext";
import { NavLink } from "react-router-dom";
import {Avatar} from "@mui/material";
import Badge from '@mui/material/Badge';
import AirplayIcon from '@mui/icons-material/Airplay';
import ShieldIcon from '@mui/icons-material/Shield';

export default function Aside() {
  const { user, Loading, userInfo } = useAuth();

  return (
    <>
      <aside
        className="main-sidebar sidebar-dark-* elevation-4"
        style={{ height: "" }}
      >
        {/* Brand Logo */}
        <a className="brand-link">
          <img
            src="../../assets/logo-min.png"
            alt="Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">VPanel</span>
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
            <Avatar sx={{ bgcolor: "orange",width: 40, height: 40  }}>{userInfo?.usuario.substring(0,2).toUpperCase()}</Avatar>
            </div>
            <div className="info">
              <span className="d-block text-sm text-light ">
                <small>{Loading ? "Cargando..." : userInfo?.correo}</small>
              </span>
            </div>
          </div>
          {/* SidebarSearch Form */}
          <div className="form-inline">
            <div className="input-group" data-widget="sidebar-search">
              <input
                className="form-control form-control-sidebar"
                type="search"
                placeholder="Ticket..."
                aria-label="Search"
              />
              <div className="input-group-append">
                <button className="btn btn-sidebar">
                  <i className="fas fa-search fa-fw" />
                </button>
              </div>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <NavLink end to="/dashboard" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p className="text-sm">Dashboard</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/tickets/inbox" className="nav-link">
                <Badge badgeContent={1} color="secondary" anchorOrigin={{vertical: 'top', horizontal: 'left',}}>
                   <AirplayIcon />
                </Badge>
                <p className="text-sm pl-1"> Mis tickets</p>
                </NavLink>
                
              </li>
              <li className="nav-item">
                <NavLink to="/tickets/new" className="nav-link">
                  <i class="nav-icon fas fa-headset"></i>
                  <p className="text-sm">Crear ticket</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/tickets/history" className="nav-link">
                  <i className="nav-icon fas fa-user-tag" />
                  <p className="text-sm">Histórico</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/tickets/reports" className="nav-link">
                  <i className="nav-icon far fa-folder" />
                  <p className="text-sm">Reportes</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={`/profile/${user.uid}`} className="nav-link">
                  <i className="nav-icon fas fa-user-alt"></i>
                  <p className="text-sm">Mi perfil</p>
                </NavLink>
              </li>
              { userInfo?.isAdmin && (
              <li className="nav-item">
                <NavLink to={"/admin/"} className="nav-link">
                <i class="nav-icon fas fa-user-shield"></i>
                  <p className="text-sm">Administrador</p>
                </NavLink>
              </li>
              )
              }
              { userInfo?.isAdmin && (
              <li className="nav-item">
                <NavLink to={"/root/main/"} className="nav-link">
                <ShieldIcon/>
                  <p className="text-sm">Root</p>
                </NavLink>
              </li>
              )
              }
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </>
  );
}
