import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { useAuth } from "../../contexts/authcontext";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {
  doc,
  updateDoc,
  collection,
  getDocs,
  orderBy,
  query,
  deleteDoc,
  where,
  addDoc, 
} from "firebase/firestore";
import {generarCodigoAleatorio,obtenerFechaActual,tiempoTranscurrido,obtenerFechaYHoraActual,localStorageAvailable} from "../functions";
import ShareIcon from '@mui/icons-material/Share';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {obtenerUIDProyecto} from '../functions';

const CardProyectos = () => {
  const { db, user, setNumProyectos,selproyecto, setSelProyecto} = useAuth();
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [proyectos, setProyectos] = useState([]);
  const [borrarProyecto, setBorrarProyecto] = useState({uid: '', proyecto: ''});
  const [editProyecto, setEditProyecto] = useState();
   
  const loadProyectos = async () => {
    
   const q = query(collection(db, 'proyectos'), where("createBy", "==", user.uid), orderBy("nombre", "asc"));
   const docSnap2 = await getDocs(q)
      .then((querySnapshot)=>{
        const arrayproyectos = [];
        querySnapshot.forEach((doc) => {
          arrayproyectos.push({ uid: doc.id, ...doc.data() });
        });
        setProyectos(arrayproyectos);
        setNumProyectos(arrayproyectos.length);
      })
      .catch((error) => {
        console.error('Error al obtener los proyectos:', error);
      });
  };

  useEffect(() => {
    loadProyectos();
  }, []);

  const handleClose = () => setShow(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShow = () => setShow(true);
  const handleShowEdit = () => setShowEdit(true);
  const handleCloseDelete = () => setShowDelete(false);
  
  const seleccionaProyecto = (index) => {
    const proyectoSeleccionado = proyectos[index];
    setSelProyecto({uid: proyectoSeleccionado.uid, nombre: proyectoSeleccionado.nombre} );
    const obj = {id: proyectoSeleccionado.uid, nombre: proyectoSeleccionado.nombre };
    const objJSON = JSON.stringify(obj);
    localStorage.setItem('proyecto', objJSON);
  }

  const deleteProyecto = async () => {
     
    const deleteProyecto = doc(db, `/proyectos`,`${borrarProyecto.uid}`);
   
     await deleteDoc(doc(db, `/proyectos/`,`${borrarProyecto.uid}`))
     .then(()=>{
      setShowDelete(false);
      loadProyectos();
     })
     .catch(()=>{
      alert("Se ha producido un error al Eliminar el Proyecto seleccionado, reintenta más tarde.");
      setShowDelete(false);
     });
  }

  const HandleSubmitEdit = async (e) => {
    e.preventDefault();
    
    const actProyecto = doc(db, `/proyectos/${editProyecto.uid}`);

    updateDoc(actProyecto,  {descripcion: e.target["descripcion"].value, nombre: e.target["nombre"].value.toUpperCase() })
    .then(function() {
      setShowEdit(false);
      loadProyectos();
    });  
  }

  const HandleShowEdit = async (uid,index) => {

    const ProyectoEditar = proyectos.find(proy => proy.uid === uid);
    setEditProyecto(ProyectoEditar);
    setEditIndex(index);
    setShowEdit(true);
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    //setuidProyecto(await generarCodigoAleatorio(20));
    //const uid = await generarCodigoAleatorio(20);    
    const Refdb = collection(db, "proyectos");
    const addProyecto = await addDoc(Refdb, {
      nombre: e.target["nombre"].value.toUpperCase(),
      descripcion: e.target["descripcion"].value,
      createdate: obtenerFechaYHoraActual(),
      createBy: user.uid,
      activo: true
    }).then((Refdb)=>{
      //alert(Refdb.id) id que se acaba de crear en proyectos
      setSelProyecto({uid: Refdb.id, nombre: e.target["nombre"].value});
      const obj = {id: Refdb.id, nombre: e.target["nombre"].value.toUpperCase() };
      const objJSON = JSON.stringify(obj);
      localStorage.setItem('proyecto', objJSON);
      loadProyectos();
      setShow(false);
    })
  }

  return (
    <>
      <div className="row">
        <div className="ml-3">
          <Card style={{ width: "18rem", cursor: "pointer" }} onClick={handleShow}>
            <Card.Body>
              <h5>Nuevo proyecto</h5>

              <Card.Text
                className="text-xl"
                style={{ textAlign: "center", color: "blue" }}
              >
                <i class="fas fa-plus-circle fa-lg"></i>
                <p
                  className="text-md"
                  style={{ textAlign: "center", color: "blue" }}
                >
                  Crear Nuevo
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>


        { proyectos.length > 0 ?  (
        
        proyectos.map((framework, index) => (
          <div className="ml-3" key={index}>
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <span
                  className="text-sm"
                  style={{
                    textAlign: "right",
                    alignContent: "flex-end",
                    float: "right",
                    paddingLeft: "2px",
                    cursor: 'pointer'
                  }}
                  title="Seleccionar proyecto"
                  onClick={()=>seleccionaProyecto(index)}
                >
                  { 
                    
                    framework?.uid == obtenerUIDProyecto()  ? (<i class="fas fa-2x fa-check-circle" style={{color:"green"}}></i>) : (<i class="far fa-circle" style={{color:"gray"}}></i>)
                  }
                 
                </span>
                <h5>{framework?.nombre}</h5>
                <p className="mb-2 text-muted text-sm">
                  {user?.email}
                </p>
                <Card.Text className="text-sm">
                  {framework?.descripcion}
                </Card.Text>
                <Card.Link href="#" title="Editar"><i class="far fa-edit" onClick={()=> HandleShowEdit(framework?.uid,index)}></i></Card.Link>
                <span className="text-xs muted ml-3">Create: { framework?.createdate }</span>
                <span
                  className="text-sm"
                  title="Eliminar..."
                  style={{
                    textAlign: "right",
                    alignContent: "flex-end",
                    float: "right",
                    paddingLeft: "2px",
                    cursor: "pointer",
                    color: "red",

                  }}
                  onClick={() => {setBorrarProyecto({uid: framework?.uid, proyecto:framework?.nombre }); setShowDelete(true);}}
                >
                  <DeleteOutlineIcon/>
                </span>
                <span
                  title="Compartir enlace de este proyecto..."
                  style={{
                    textAlign: "right",
                    alignContent: "flex-end",
                    float: "right",
                    paddingright: "5px",
                    cursor: "pointer",
                    color: "gray",

                  }}
                  onClick={() => {setBorrarProyecto({uid: framework?.uid, proyecto:framework?.nombre }); setShowDelete(true);}}
                >
                  <ShareIcon sx={{width: "20px", paddingRight: "5px"}} />
                </span>
              </Card.Body>
            </Card>
          </div>
        ))) :("")} 
      </div>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={HandleSubmit}>
          <Modal.Header>
            <Modal.Title>Crear nuevo proyecto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nombre del proyecto</Form.Label>
              <Form.Control
                type="text"
                placeholder="escribe un nombre"
                autoFocus
                name="nombre"
                required
                maxLength={25}
                minLength={3}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Descripción</Form.Label>
              <Form.Control type="textarea" name="descripcion" rows={3} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              Crear
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>


      <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header>
            <Modal.Title>Confirma que quieres eliminar...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  { <span><b>{borrarProyecto.proyecto}</b></span> }
                  <p className="muted mt-2 text-sm">Atención: al eliminar el Proyecto se elmininarán todos los datos asociados a él.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Cancelar
            </Button>
            <Button variant="danger" type="button" onClick={deleteProyecto}>
              Eliminar
            </Button>
          </Modal.Footer>
      </Modal>


      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Form onSubmit={HandleSubmitEdit}>
          <Modal.Header>
            <Modal.Title>Editar proyecto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nombre del proyecto</Form.Label>
              <Form.Control
                type="text"
                placeholder="escribe un nombre"
                autoFocus
                name="nombre"
                defaultValue={editProyecto?.nombre}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Descripción</Form.Label>
              <Form.Control type="textarea" name="descripcion" rows={3} defaultValue={editProyecto?.descripcion} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEdit}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              Modificar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CardProyectos;
