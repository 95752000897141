import React from 'react'

const WidgetContactoInterno = () => {
  return (
    <>
     <div className="col-md-3 col-sm-6 col-12 mt-2">
        <div className="info-box">
          <span className="info-box-icon bg-danger">
            <i className="far fa-star" />
          </span>
          <div className="info-box-content">
            <span className="info-box-text">Contactos Interno</span>
            <span className="info-box-number">93,139</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default WidgetContactoInterno