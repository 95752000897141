import { createContext, useContext, useState, useEffect } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db, messaging } from "../firebase";
import { doc, getDoc, updateDoc,getDocs,query,collection,where, orderBy } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import { getToken, onMessage } from "firebase/messaging";

export const context = createContext();

export const useAuth = () => {
  const userAuthContext = useContext(context);
  return userAuthContext;
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [numregistros, setNumRegistros] = useState("");
  const [uidProyecto, setuidProyecto] = useState("");
  const [userInfo, setuserInfo] = useState(null); //info de Collec. usuarios
  const [numProyectos, setNumProyectos] = useState(0); //Numero de proyectos en CardProyectos
  const [numUsuarios, setNumUsuarios] = useState(0); //Numero de usuarios de un proyecto seleccionado en ListUsuarios
  const [numClientes, setNumClientes] = useState(0); //Numero de clientes de un proyecto seleccionado en ListClientes
  const [selproyecto, setSelProyecto] = useState(); //proyecto seleccionado en CardProyectos por defecto
  const [numAgentes, setNumAgentes] = useState(0);
  const [numContactos, setNumContactos] = useState(0);
  const [numGrupos, setNumGrupos] = useState(0);
  const [numCategorias, setNumCategorias] = useState(0);
  const [lock, setLock] = useState(false);
  const [notiMesagges, setNotiMesagges] = useState();
  const [proyectos, Setproyectos] = useState([]);
  const [mensaje, setMensaje] = useState({
    texto: "",
    show: false,
    color: "success",
  });
  const [archivos, setArchivos] = useState([]);
  const [notification, setNotification] = useState([]);
  const [messagesNum, setMessagesNum] = useState(0);
  const [backdrop, setBackdrop] = useState(false);

  const navigate = useNavigate();

  async function obtenerToken() {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        getToken(messaging, {
          vapidKey:
            "BA3ltUp9BVk_3c_vYEsAmaRVO_58ipUsB1WOZQ8pG91Gc8xZd1gODGOjhFya07bW_6i3ZhavJ2EUOzCFDy9qtTo",
        })
          .then((currentToken) => {
            if (currentToken) {
              const UserTocken = doc(db, `usuarios/${auth.currentUser.uid}`);
              const update = updateDoc(UserTocken, {
                notificationToken: currentToken,
              });
            } else {
              // Show permission request UI
            //  console.log(
            //    "No registration token available. Request permission to generate one."
            //  );
              // ...
            }
          })
          .catch((err) => {
            alert ("An error occurred while retrieving token. ");
          });
      }
    });
  }

  useEffect(() => {
    
    onMessage(messaging, (message) => {
      const beepSound = new Audio('../../../../assets/beep.mp3');
      beepSound.play();
      setMensaje({show: true, texto: `MENSAJE! ${message.notification.title}` , color: 'info'});
    });
  }, []);

  useEffect(() => {
    if (auth.currentUser) {
      updateUsersTracks(auth.currentUser);
      } else{
    //  ("no hay usuario autenticado")
    }
}, [auth.currentUser])

const location = useLocation();


const obtenerNomProy = async (uid) => {
  const docRef = doc(db, "proyectos", `${uid}`);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().nombre;
    } else {
      return null; // O manejar el caso en el que el documento no existe
    }
  } catch (error) {
    console.error(error);
    return null; // O manejar el error de alguna otra forma
  }
}

  const updateUsersTracks = async (currentUser) => {
       
    //si no se puede utlizar localstorage no podemos continuar y salimos de la sesión
    if (typeof window !== "undefined" && window.localStorage) {
       
      const docRef = doc(db, "usuarios", `${currentUser.uid}`);
      const docSnap = await getDoc(docRef)
      .then(async (docSnap)=>{

        if (docSnap.exists()) {
          if (docSnap.data().activo === true) {
            setuserInfo(docSnap.data());
            setLock(docSnap.data().isLocked);
            setUser((prevState) => ({
              ...prevState,
              displayName: docSnap.data().usuario,
            }));
            const proyectoNom = await obtenerNomProy(docSnap.data().proyectos[0]);
            const obj = {id: docSnap.data().proyectos[0], nombre: proyectoNom };
            const objJSON = JSON.stringify(obj);
            localStorage.setItem('proyecto', objJSON);
              const url = location.pathname;
              if (url == '/' || url == '/login') {
                navigate("/dashboard");
              } else {
                navigate(url);
              }
          } else {
            setuserInfo(null);
            Logout();
            navigate("/inactive");
          }
        } else {
          setuserInfo(null);
          Logout();
          navigate("/login"); //si no se encuentra el usuario en usuarios no deja iniciar sesion algo raro ha ocurrido.
        }
        
      })
      .catch(()=>{
        setMensaje({show: true, texto: `Ha ocurrido un problema al validar tu cuenta!` , color: 'error'})
        Logout();
        navigate("/login");
      })


    } else {
      // no puedes utilizar localStorage
      setMensaje({show: true, texto: "Parece ser que tu navegador no cumple con los requerimientos mínimos para ejecutar la aplicación." , color: 'error'});
      Logout();
      navigate("/login");
    }
  };

  const LoadUserAuthInfo = async (UID) => {
    
    const docRef = doc(db, "usuarios", `${UID}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (docSnap.data().activo === true) {
        setuserInfo(docSnap.data());
        setLock(docSnap.data().isLocked);
      } else {
        setuserInfo(null);
        Logout();
        navigate("/inactive");
      }
    } else {
      setuserInfo(null);
      Logout();
      navigate("/login"); //si no se encuentra el usuario en usuarios no deja iniciar sesion algo raro ha ocurrido.
    }
  };

  const loadMessages = async () => {
    const q = query(collection(db, "messages"),
      where("destinatario", "array-contains", auth.currentUser.uid),
      orderBy("createTimestamp", "desc"))
    const docSnap = await getDocs(q).then((querySnapshot) => {
      const datos = [];
      querySnapshot.forEach((doc) => {
        datos.push({
          id: doc.id,
          mensaje: doc.data().mensaje,
          createAt: doc.data().createAt,
          ticket: doc.data().ticket,
          url: doc.data().url,
          senderNom: doc.data().senderNom,
        });
      })
      
      setNotiMesagges(datos);
      setMessagesNum(datos.length);
    })
    .catch((error)=>{alert("Ha ocurrido un error al recuperar Mensajes.")})

  }

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
         
      if (currentUser) {
        LoadUserAuthInfo(currentUser.uid);
        obtenerToken();
        loadMessages();
      }
    });
  }, []);

  const Logout = () => {
    const obj = {id: '', nombre: '' };
    const objJSON = JSON.stringify(obj);
    localStorage.setItem('proyecto', objJSON);
    signOut(auth)
  };

  return (
    <context.Provider
      value={{
        user,
        Logout,
        loading,
        numregistros,
        setNumRegistros,
        db,
        uidProyecto,
        updateUsersTracks,
        userInfo,
        setuserInfo,
        numProyectos,
        setNumProyectos,
        selproyecto,
        setSelProyecto,
        numUsuarios,
        setNumUsuarios,
        setNumClientes,
        numClientes,
        numAgentes,
        setNumAgentes,
        setNumContactos,
        numContactos,
        setNumGrupos,
        numGrupos,
        lock,
        setLock,
        mensaje,
        setMensaje,
        numCategorias,
        setNumCategorias,
        archivos,
        setArchivos,
        notification,
        setNotification,
        LoadUserAuthInfo,
        notiMesagges, loadMessages, messagesNum,
        proyectos, Setproyectos,
        backdrop, setBackdrop,
      }}
    >
      {children}
    </context.Provider>
  );
}
