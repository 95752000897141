import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  serverTimestamp,
  documentId,
} from "firebase/firestore";
import { useAuth } from "../../contexts/authcontext";
import "@fontsource/roboto/300.css";
import { useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import Badge from "@mui/material/Badge";
import { Stack } from "@mui/system";
import {
  calcularTiempoTranscurrido,
  obtenerFechaYHoraActual,
  obtenerUIDProyecto,
} from "../functions";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import ContentHeader from "../ContentHeader";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import "moment/locale/es";
import { NavLink } from "react-router-dom";
import ForumIcon from "@mui/icons-material/Forum";
import { getStorage, ref, listAll } from "firebase/storage";
import { Button, Modal } from "react-bootstrap";
import { TextField } from "@mui/material";
import { pink } from "@mui/material/colors";
import CloudOffIcon from '@mui/icons-material/CloudOff';
import "./styles.css";


const ListTickets = () => {
  const [data, setData] = useState([]);
  const [dataUsuarios, setDataUsuarios] = useState([]);
  const [dataGrupos, setDataGrupos] = useState([]);
  const [refreshTable, setrefreshTable] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [showModalCreate, setshowModalCreate] = useState(false);
  const [showModalReevniar, setShowModalReenviar] = useState(false);
  const [changegrupo, setChangegrupo] = useState({id: '', nombre: '' });
  const [bandeja,setBandeja] = useState({sel: 2, inbox: 'light', send: 'secondary', all: 'light'})
  const { setMensaje, user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { comment: "" },
  });

  useEffect(() => {
    loadUser();
    listTicket();
    loadGrupos();
  }, [bandeja]);

  const storage = getStorage();
  
  const loadUser = async () => {
    const q = query(collection(db, "usuarios"));
    const docSnap = await getDocs(q).then((querySnapshot) => {
      const datos = [];
      querySnapshot.forEach((doc) => {
        datos.push({
          id: doc.id,
          usuario: doc.data().usuario,
        });
      });
      setDataUsuarios(datos);
    });
  };

  const loadGrupos = async () => {
    const q = query(collection(db, "grupos"),
    where("proyectoID", "==", obtenerUIDProyecto()),);
    const docSnap = await getDocs(q).then((querySnapshot) => {
      const datos = [];
      querySnapshot.forEach((doc) => {
        datos.push({
          id: doc.id,
          nombre: doc.data().nombre,
        });
      });
      setDataGrupos(datos);
      setChangegrupo({id: datos[0].id, nombre: datos[0].nombre});
    });
  };

  const listTicket = async () => {
    setrefreshTable(true);
    let q = '';
    if (bandeja.sel == 1) {
      q = query(
        collection(db, "tickets"),
        where("proyectoID", "==", obtenerUIDProyecto()),
        where("destinatario", "array-contains", user.uid),
        orderBy("createTimestamp", "desc")
      );
    }

    if (bandeja.sel == 2) {
      q = query(
        collection(db, "tickets"),
        where("proyectoID", "==", obtenerUIDProyecto()),
        where("createdBY", "==", user.uid),
        orderBy("createTimestamp", "desc")
      );
    }

    if (bandeja.sel == 3) {
      q = query(
        collection(db, "tickets"),
        where("proyectoID", "==", obtenerUIDProyecto()),
        orderBy("createTimestamp", "desc")
      );
    }

    const docSnap = await getDocs(q)
      .then((querySnapshot) => {
        const datos = [];

        querySnapshot.forEach((doc) => {
          datos.push({
            id: doc.id,
            ticketID: "TKT-" + doc.data().ticketID,
            createdBY: doc.data().createdBY,
            categoriaID: doc.data().categoriaID,
            createdate: doc.data().createdate,
            asunto: doc.data().asunto,
          //  comentarios: doc.data().comentarios,
            empresa: doc.data().empresa,
            activo: doc.data().activo === true ? true : false,
            conversations: doc.data().conversations,
            categoriaNOM: doc.data().categoriaNOM,
            bucketIDAttachfiles: doc.data().bucketIDAttachfiles,
            estado: doc.data().estado,
            departamentoNOM: doc.data().departamentoNOM,
            prioridad: doc.data().prioridad,
          });
        });

        setData(datos);
        setrefreshTable(false);
      })
      .catch((error) => {
        console.error(error)
        setMensaje({
          show: true,
          texto: `Ha ocurrido un problema de lectura!`,
          color: "danger",
        });
      });
  };

  // Función para verificar si un bucket está vacío
  async function verificarBucketVacio(bucketName) {
    try {
      const bucketRef = ref(storage, bucketName);
      const files = await listAll(bucketRef);
      return files.items.length === 0;
    } catch (error) {
      alert("Error al verificar el estado del bucket:");
    }
  }

  const handleChangeGrupo = (event) => {
    setChangegrupo({id: event.target.value, nombre: event.target.options[event.target.selectedIndex].text });
  }

  const handleSubmitchangeGrupo = () => {
      selectionModel.forEach( async (ticket) => {
     
            const agentes = [];
            let departamentoNOM = '';
            const agentesRef = collection(db, "grupos");
            const q = query(
              agentesRef,
              where(documentId(), "==", changegrupo.id)
            );
            const querySnapshot = await getDocs(q).then(async (querySnapshot) => {
              if (!querySnapshot.empty) {
                departamentoNOM = querySnapshot.docs[0].data().nombre;
                querySnapshot.docs[0].data().agentes.forEach((agente) => {
                  agentes.push(agente);
                });
                const change = doc(db,`tickets/${ticket}`);
                await updateDoc(change, { destinatario: agentes, departamentoID: changegrupo.id, departamentoNOM: departamentoNOM})
                  .then(() => {
                    setMensaje({
                      show: true,
                      texto: `Se ha reenviado el TICKET con éxto!`,
                      color: "success",
                    });
                    setShowModalReenviar(false);
                    listTicket();
                    
                  })
                  .catch(() => {
                    setMensaje({
                      show: true,
                      texto: `Ha ocurrido un problema al reenviar el TICKET!`,
                      color: "danger",
                    });
                  });
              }
            });
      })
  }

  const selBandeja = (bandeja) => {
    if (bandeja == 1) { setBandeja({sel: 1, inbox: 'secondary', send: 'light', all: 'light'})}
    if (bandeja == 2) { setBandeja({sel: 2, inbox: 'light', send: 'secondary', all: 'light'})}
    if (bandeja == 3) { setBandeja({sel: 3, inbox: 'light', send: 'light' , all: 'secondary'})}
  }

  const responseTiket = async () => {
    if (selectionModel.length == 0) {
      setMensaje({
        show: true,
        texto: `Selecciona al menos un Ticket de la lista!!`,
        color: "primary",
      });
      return
    }
    
    if (selectionModel.length > 1) {
      setMensaje({
        show: true,
        texto: `Para esta Operación solo puede tener un Ticket seleccionado!!`,
        color: "primary",
      });
      return
    } else {
      setshowModalCreate(true);
    }
  };

  const responseReenviar = () => {

    if (selectionModel.length == 0) {
      setMensaje({
        show: true,
        texto: `Selecciona al menos un Ticket de la lista!!`,
        color: "primary",
      });
      return
    }
    setShowModalReenviar(true);
  }

  const HandleCreateSubmit = async (data) => {
    const ticketID = selectionModel[0];
    const archivos = [];
    const docRef = doc(db, "tickets", ticketID);
    const docSnap = await getDoc(docRef).then(async (docu) => {
      if (docu.exists()) {
        const destinatario = [];
        destinatario.push(docu.data().createdBY);
        const refTicket = docu.data().ticketID;
        const Bucket = docu.data().bucketIDAttachfiles;
        const createComment = await addDoc(
          collection(db, `tickets/${ticketID}/conversations`),
          {
            activo: true,
            senderID: user.uid,
            mensaje: data.comment,
            createAt: obtenerFechaYHoraActual(),
            attachFiles: archivos,
            createTimestamp: serverTimestamp(), // Utiliza serverTimestamp() para obtener la fecha y hora actual del servidor
            senderNom: "",
          }
        );

        await addDoc(collection(db, `messages`), {
          read: false,
          senderID: user.uid,
          mensaje: data.comment,
          createAt: obtenerFechaYHoraActual(),
          createTimestamp: serverTimestamp(),
          senderNom: "",
          url: `${ticketID}/${Bucket}`,
          destinatario: destinatario,
          ticket: refTicket,
        });

        const updateConversacion = doc(db, `/tickets/${ticketID}`);
        await updateDoc(updateConversacion, { conversations: true });

        setMensaje({
          show: true,
          texto: `Se ha enviado el comentario con Éxito!!`,
          color: "success",
        });
      } else {
        setMensaje({
          show: true,
          texto: `Ha ocurrido un problema al recuperar el TICKET!!`,
          color: "danger",
        });
      }
    });
    reset({ comment: "" });
    setshowModalCreate(false);
    listTicket();
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "ticketID",
      headerName: "Ticket",
      width: 75,
      renderCell: (param) => {
        return (
          <div className="m-0 p-0">
            <NavLink
              end
              to={`/tickets/detail/${param.row.id}/${param.row.bucketIDAttachfiles}`}
            >
              {param.row.ticketID}
            </NavLink>
          </div>
        );
      },
    },
    {
      field: "attachFiles",
      headerName: "",
      renderCell: (param) => {
        return (
          <AttachFileIcon
            style={{ fontSize: "15px" }}
            titleAccess={`El Ticket ${param.row.ticketID} contiene archivos adjuntos`}
          />
        );
      },
    },
    {
      field: "conversations",
      headerName: "",
      renderCell: (param) => {
        return (
          param.row.conversations === true && (
            <div className="m-0 p-0">
              <NavLink
                end
                to={`/tickets/detail/${param.row.id}/${param.row.bucketIDAttachfiles}`}
              >
                <ForumIcon
                  style={{ fontSize: "15px" }}
                  titleAccess={`El Ticket ${param.row.ticketID} contiene conversaciones`}
                />
              </NavLink>
            </div>
          )
        );
      },
    },
    {
      field: "createdBY",
      headerName: "Remitente",
      width: 125,
      valueGetter: (param) => {
        const usuario = dataUsuarios.find(
          (item) => item.id === param.row.createdBY
        );
        return usuario ? usuario?.usuario : "Not found";
      },
      renderCell: (params) => (
        <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: '12px' }}>
          <b>{params.value}</b>
        </div>
      ),
    },
    { field: "asunto", headerName: "Incidente", width: 200, },
    { field: "categoriaNOM", headerName: "Tipo", width: 130, 
    renderCell: (params) => (
      <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: '12px' }}>
        {params.value}
      </div>
    ),
    },
    { field: "estado", headerName: "Estado", width: 100 },
    {
      field: "departamentoNOM",
      headerName: "Asignado",
      width: 125,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: '12px' }}>
          {params.value}
        </div>
      ),
    },
    { field: "prioridad", headerName: "Prioridad", width: 100 },
    {
      field: "createdate",
      headerName: "Fecha",
      width: 150,
      renderCell: (param) => {
        return (
          <div
            style={{ fontSize: "12px" }}
            onClick={() => alert(param.row.createdate)}
          >
            {calcularTiempoTranscurrido(param.row.createdate)}
          </div>
        );
      },
    },
    {
      field: "activo",
      headerName: "Activo",
      width: 150,
      renderHeader: () => (
        <div style={{ textAlign: "center", color: "#333" }}>
          <b>Activo</b>
        </div>
      ),
      renderCell: (param) => {
        let icon = "";
        if (param.row.activo == true) {
          icon = <DoneAllIcon style={{ color: "green" }} />;
        } else {
          icon = <RemoveDoneIcon style={{ color: "red" }} />;
        }

        return <div style={{ textAlign: "center" }}>{icon}</div>;
      },
    },
  ];

  function NoRows() {
    return (
       <div style={{display: 'flex',
       flexDirection: 'column',
       alignItems: 'center',
       justifyContent: 'center',
       marginTop: '30px',}}> 
          <CloudOffIcon sx={{marginBottom: '10px', color:'gray'}}/>
          <span style={{ color:  'GrayText'}}>No se han encontrado registros.</span>
       </div> 
          
    
    );
  }

  return (
    <>
      <ContentHeader
        title="Mis Tickets"
        //subtitle={`#Track-Id-${uuid}`}
        back="Crear Ticket"
        nav="/tickets/new"
      />
      <div className="col-12 col-sm-12 col-md-12" style={{backgroundColor: '#FDFDFD'}}>
        <div className="card card-primary card-outline">
          <div className="card-header">
            <h3 className="card-title">
              <span className="pt-5 mt-5">
                <i class="fa fa-download" aria-hidden="true"></i>
                <span className="ml-2"> Bandeja de entrada</span>
              </span>
            </h3>
            <div className="card-tools">
              <div className="btn-group">
                {selectionModel.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-tool"
                    title="Eliminar Tickets seleccionados."
                    // onClick={() => setopenDeleteModal(true)}
                  >
                    <Badge
                      badgeContent={selectionModel.length}
                      sx={{ color: pink[500] }}
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                      <DeleteIcon />
                    </Badge>
                  </button>
                )}
              </div>

              <button
                type="button"
                className="btn btn-tool"
                data-card-widget="maximize"
              >
                <i className="fas fa-expand"></i>
              </button>
            </div>
          </div>
          <div className="mailbox-controls">
            {/* Check all button */}

            <div className="btn-group">
              <button
                type="button"
                className="btn btn-default btn-sm"
                style={{ color: "red" }}
                title="Eliminar seleccionados"
              >
                <i className="far fa-trash-alt" />
              </button>
              <button
                type="button"
                className="btn btn-default btn-sm"
                title="Responder al Remitente del ticket"
                onClick={responseTiket}
              >
                <i className="fas fa-reply" />
              </button>
              <button
                type="button"
                className="btn btn-default btn-sm"
                title={`Reenviar (${selectionModel.length}) Ticket(s) seleccionado(s)`}
                onClick={responseReenviar}
              >
                <i className="fas fa-share" />
              </button>
            </div>
            {/* /.btn-group */}
            <button
              type="button"
              className="btn btn-default btn-sm ml-2 mr-10"
              title="Actualizar la lista de Tickets"
              onClick={() => {
                setrefreshTable(true);
                listTicket();
              }}
            >
              <i className="fas fa-sync-alt" />
            </button>
            <Button
              variant={bandeja.inbox}
              size="sm"
              style={{ marginLeft: "50px", borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
              onClick={()=> selBandeja(1)}
              title="Tickets recibidos"
            >
              <i class="fa fa-download" aria-hidden="true"></i> Entrada
            </Button>
            <Button variant={bandeja.send} size="sm"  onClick={()=>selBandeja(2)} title="Tickets enviados">
              <i class="fa fa-upload" aria-hidden="true"></i> Enviados
            </Button>
            <Button variant={bandeja.all} size="sm"  onClick={()=>selBandeja(3)} title="Todos los Tickets del bucket">
              <i class="fa fa-download" aria-hidden="true"></i> Todos
            </Button>
           {/*  <div className="float-right">
              <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm">
                  <i className="fas fa-chevron-left" />
                </button>
                <button type="button" className="btn btn-default btn-sm">
                  <i className="fas fa-chevron-right" />
                </button>
              </div>
              
            </div> 
           */}
          </div>

          <div style={{ height:'auto',width: "100%",  }}>
            <DataGrid
                initialState={{
                pagination: {
                  paginationModel: { pageSize: 25 },
                 
                },
                columns: { columnVisibilityModel: { id: false } },
                
              }}
              slots={{
                noRowsOverlay: NoRows,
                noResultsOverlay: NoRows,
                toolbar: GridToolbar,
              }}
              disableRowSelectionOnClick
              columns={columns}
              rows={data}
              autoHeight
              pageSize={10}
              pageSizeOptions={[25]}
              getCellClassName={(params) => {
                if (params.field == "estado" && params.value == "Open") {
                  return "open";
                }
                if (params.field == "estado" && params.value == "Close") {
                  return "cerrado";
                }
                if (params.field == "estado" && params.value == "Re-Open") {
                  return "re-open";
                }
                if (params.field == "prioridad" && params.value == "Urgente") {
                  return "danger";
                }
                //  return params.value >= 15 ? 'hot' : 'cold';
              }}
              components={{
                noRowsLabel: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No se han encontrado Tickets de servicio
                  </Stack>
                ),
                noResultsOverlayLabel: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No se han encontrado coincidencias
                  </Stack>
                ),
              }}
              localeText={{
                columnsPanelShowAllButton: "Show All",
                columnsPanelTextFieldLabel: "Buscar columna",
                columnMenuFilter: "Filtrar",
                columnMenuHideColumn: "Ocultar",
                columnMenuShowColumns: "Columnas...",
                noRowsLabel: " No se han encontrado Tickets de servicio",
                footerRowSelected: (count) =>
                  count !== 1
                    ? `${count.toLocaleString()} registro(s) seleccionado(s)`
                    : `${count.toLocaleString()} registro seleccionado`,
              }}
              checkboxSelection
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              loading={refreshTable}
              selectionModel={selectionModel}
              
            />
          </div>
        </div>
      </div>

      <Modal show={showModalCreate} onHide={() => setshowModalCreate(false)}>
        <form onSubmit={handleSubmit(HandleCreateSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Responder al remitente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextField
              id="outlined-multiline-static"
              label="Comentarios"
              multiline
              autoFocus
              rows={5}
              fullWidth
              {...register("comment", {
                minLength: 3,
                required: true,
                autoFocus: true,
              })}
            />
            {errors.comment?.type === "required" && (
              <span className="text-xs pl-1 text-danger">
                Es obligatorio escribir un comentario.
              </span>
            )}
            {errors.comment?.type === "minLength" && (
              <span className="text-xs pl-1 text-danger">
                El comentario es demasiado corto.
              </span>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setshowModalCreate(false)}
            >
              Cerrar
            </Button>
            <Button variant="primary" type="submit">
              Enviar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showModalReevniar} onHide={() => setShowModalReenviar(false)}>
          <Modal.Header>
            <Modal.Title><h5>Reenviar el Ticket:</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
           <select value={changegrupo.id} onChange={handleChangeGrupo} style={{padding:'5px', width: '100%', cursor:'pointer'}}>
            { dataGrupos.map((item,index) =>
            <option key={index} value= {item?.id}>{item?.nombre}</option>
            )}
           </select>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowModalReenviar(false)}
            >
              Cerrar
            </Button>
            {
              dataGrupos.length > 1 &&
           (
            <Button variant="primary" type="button" onClick={handleSubmitchangeGrupo}>
              Reenviar
            </Button>
             )}
          </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListTickets;
