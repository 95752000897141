import React, { useState, useEffect } from "react";
import { DataGrid, GridFooter, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarContainer,
GridToolbarExport, GridToolbarQuickFilter, GridToolbarDensitySelector,  } from "@mui/x-data-grid";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
  query,where,orderBy
} from "firebase/firestore";
import { useAuth } from "../../contexts/authcontext";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "@mui/material";
import "@fontsource/roboto/300.css";
import { obtenerFechaYHoraActual } from "../functions";
import { useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import Badge from "@mui/material/Badge";
import { FormControlLabel, IconButton } from "@mui/material";
import { blue, pink, grey } from "@mui/material/colors";
import { Edit } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { obtenerUIDProyecto, obtenerNOMProyecto,localeText } from "../functions";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import RefreshIcon from "@mui/icons-material/Refresh";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import "../../components/styles.css";

const ListClientes = ({ rows }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [data, setData] = useState([]);
  const [showModalCreate, setshowModalCreate] = useState(false);
  const [showModalEdit, setshowModalEdit] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [refreshTable, setrefreshTable] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [action, setAction] = useState('new'); // 'new' para crear, 'edit' para editar

  const { selproyecto, user, setNumClientes, setMensaje, setBackdrop } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    listClientes();
  }, [selproyecto]);

  useEffect(() => {
    reset(dataEdit);
    reset();
  }, [dataEdit, reset]);

  const listClientes = async () => {
    setrefreshTable(true);
    const q = query(collection(db, 'clientes'), where("proyectoID", "==", obtenerUIDProyecto()), orderBy("nombre", "asc"));
    const docSnap = await getDocs(q)
    .then((querySnapshot)=>{
      const datos = [];
      querySnapshot.forEach((doc) => {
        datos.push({
          id: doc.id,
          email: doc.data().email,
          nombre: doc.data().nombre,
          comentarios: doc.data().comentarios,
          activo: doc.data().activo === true ? true : false,
          persona:  doc.data().persona,
        });
      })
      setData(datos);
      setNumClientes(datos.length);
      setrefreshTable(false);
    })
    .catch((error)=>{
      setMensaje({show: true, texto: `Ha ocurrido un problema de lectura al cargar las Empresas! ${error}` , color: 'error'})
    })
    
  };

  
  const columns = [
    { field: "id", headerName: "ID", width: 150, hide: true, },
    { field: "nombre", headerName: "Organización", width: 150, headerClassName: 'HeaderGrid' },
    { field: "persona", headerName: "Contacto", width: 150, headerClassName: 'HeaderGrid' },
    { field: "email", headerName: "Correo", width: 200, headerClassName: 'HeaderGrid' },
    { field: "activo", headerName: "Activo", width: 75, align: "center", headerClassName: 'HeaderGrid',
      renderHeader: () => <div style={{ textAlign: 'center', color: "#333" }}><b>Activo</b></div>,
      renderCell: (param) => {
        let icon = "";
        if (param.row.activo == true) {icon = <DoneAllIcon style={{ color: "green"}}/>} else  {icon = <RemoveDoneIcon style={{ color: "red"}}/>}
        
        return (
          <div style={{ textAlign: 'center' }}>{icon}</div>
        )
      }
    },
    { field: "comentarios", headerName: "Comentarios", width: 250, headerClassName: 'HeaderGrid' },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      headerClassName: 'HeaderGrid',
      width: 140,
      disableClickEventBubbling: true,
      disableButton,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatEdit index={params.row.id} />
          </div>
        );
      },
    },
  ];

  const MatEdit = ({ index }) => {
    const handleEditClick = async () => {
      setAction('edit');
      setrefreshTable(true)
      //Buscamos el cliente por su uid
      const docRef = doc(db, `clientes`, `${index}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        
        setDataEdit(
          {
           id: docSnap.id, 
           nombre: docSnap.data().nombre, 
           email: docSnap.data().email, 
           comentarios: docSnap.data().comentarios, 
           activo: docSnap.data().activo, 
           persona: docSnap.data().persona, 
           telefono: docSnap.data().telefono
          })
        setshowModalCreate(true);   
        setrefreshTable(false); 
      } else {
        
        setMensaje({show: true, texto: `Ha ocurrido un problema al recuperar la empresa!` , color: 'error'})
      }
     
    };

    return (
      <FormControlLabel
        control={
          <IconButton
            color="secondary"
            aria-label="Editar..."
            onClick={handleEditClick}
            title="Editar empresa"
          >
            <Edit style={{ color: blue[500] }} />
          </IconButton>
        }
      />
    );
  };

  const HandleFormSubmit = async (data) => {
    
    if (action === 'new') {

      setBackdrop(true);

      const createCliente = await addDoc(collection(db,`clientes`),
          {
            activo: true,
            nombre: data.nombre.toUpperCase(),
            email: data.email,
            proyectoID: obtenerUIDProyecto(),
            createdBY: user.uid,
            comentarios: data.comentarios,
            createdate: obtenerFechaYHoraActual(),
            persona: data.persona.toUpperCase(),
            telefono: data.telefono,
          }
        ).then(() => {
          setMensaje({show: true, texto: `Se ha creado la empresa ${data.nombre.toUpperCase()} con éxito!`, color: 'success'});
          setshowModalCreate(false);
          listClientes();
          setBackdrop(false);
          resetForms();
        }).catch((error) => {
          setMensaje({show: true, texto: `Ha ocurrido un error al meodificar la empresa!` , color: 'error'});
          setBackdrop(false);
        });
        

    } //new

    if (action === 'edit') {

      setBackdrop(true);

      const actRegistro = doc(db, `clientes/${dataEdit.id}`);

      const update = await updateDoc(actRegistro,  {nombre: data.nombre.toUpperCase(), email: data.email, comentarios: data.comentarios,
             // activo: JSON.parse(data.activo),
              persona: data.persona.toUpperCase(), telefono: data.telefono})
      .then(function() {
          setshowModalCreate(false);
          listClientes();
          resetForms();
          setMensaje({show: true, texto: 'La empresa se ha modificado con éxito!', color: 'success'});
          setDataEdit(null);
          setBackdrop(false);
       })
      .catch(()=>{
          setMensaje({show: true, texto: 'Ha ocurrido un error al meodificar la Empresa!', color: 'error'});
          setBackdrop(false);
      })

    } //edit

    
  };

  const resetForms = () =>{
    reset({nombre: '', email: '', comentarios: '',persona: '', telefono: ''});
    setDataEdit(null);
  }

  const handleModalCreateClose = () => {
    setshowModalCreate(false);
    resetForms();
  };

  const handleCloseDeleteModal = () => {
    setopenDeleteModal(false);
  };

  const HandleDeleteSelected = async () => {
    setrefreshTable(true);
    selectionModel.map((cliente) => {
      const deleteClientesSeleccionado = doc( db,"clientes", cliente );
      deleteDoc(deleteClientesSeleccionado);
    });
    listClientes();
    setopenDeleteModal(false);
    setMensaje({show: true, texto: `Se ha eliminado la empresa(s) seleccionada(s) con éxito!` , color: 'success'})
  };

   function CustomToolbar() {
    return (
      <>
      <Row>
        <Col md={7}>
      <GridToolbarContainer sx={{paddingLeft: '12px' }}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
      </Col>
      <Col md={5} style={{textAlign: 'right', }}>
        <GridToolbarQuickFilter sx={{ paddingRight: '10px'  }} />
      </Col>
      </Row>
      </>
    );
  }

  return (
    <>
      <div className="col-12 col-sm-12 col-md-12">
        <div className="card card-primary card-outline">
          <div className="card-header">
            <h3 className="card-title" style={{marginTop: '8px'}}>Empresas <RefreshIcon sx={{cursor:'pointer', color: 'gray'}} titleAccess="Recargar lista" onClick={listClientes} /></h3>
            <div className="card-tools">
              <div className="btn-group">
                {selectionModel.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-tool"
                    title="Eliminar Empresas seleccionadas."
                    onClick={() => setopenDeleteModal(true)}
                  >
                    <Badge
                      badgeContent={selectionModel.length}
                      sx={{ color: pink[500] }}
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                      <DeleteIcon />
                    </Badge>
                  </button>
                )}

                <div>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    Opciones
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setAction('new');
                        setshowModalCreate(true);
                      }}
                    >
                      <AddCircleOutlineOutlined sx={{ color: blue[500] }} />
                      <span className="text-sm pl-2"> Nueva Empresa</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setopenDeleteModal(true);
                      }}
                      disabled={selectionModel.length < 1}
                    >
                      <DeleteIcon sx={{ color: pink[500] }} />{" "}
                      <span className="text-sm pl-2">
                        Eliminar ({selectionModel.length}) seleccionados
                      </span>
                    </MenuItem>
                  </Menu>
                </div>
              </div>

              <button
                type="button"
                className="btn btn-tool"
                data-card-widget="maximize"
              >
                <i className="fas fa-expand"></i>
              </button>
            </div>
          </div>
          <div style={{ height: 'auto', width: "100%" }}>
          <DataGrid
              sx={{fontSize: '13px'}}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 20, page: 0 },
                },
                  columns: { columnVisibilityModel: { id: false } },
              }}
              
              disableRowSelectionOnClick
              columns={columns}
              rows={data}
              slots={{
              //  columnMenu: MyCustomColumnMenu,
                toolbar: CustomToolbar,
              }}
              slotProps={{
              //  columnMenu: { background: 'red', counter: rows },
                pagination: { labelRowsPerPage: '' }
              }}
              pageSizeOptions={[20]}
              autoHeight
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No se han encontrado Registros
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No se han encontrado coincidencias
                  </Stack>
                ),
               
                
              }}
              localeText={{localeText}}
              
              checkboxSelection
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              loading={refreshTable}
              selectionModel={selectionModel}
             
            />
          </div>
        </div>
      </div>

      <div>
        <Modal show={showModalCreate} onHide={handleModalCreateClose} size="lg" backdrop='static'> 
        
          <Form onSubmit={handleSubmit(HandleFormSubmit)}
          >
            <Modal.Header>
              <Modal.Title>
                {action === 'new' ? 'Crear nueva empresa' : 'Editar empresa'}
                <span className="muted text-md  ml-2">
                  (Proy: {obtenerNOMProyecto()})
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs="12" lg="6">
                  <Form.Group
                    className="mb-4"
                  >
                    <Form.Label className="labelForms">Empresa*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nombre completo"
                      autoFocus
                      {...register("nombre", {
                        minLength: 3,
                        required: true,
                        autoFocus: true,
                      })}
                      autoComplete="off"
                      defaultValue={action === 'edit' ? dataEdit?.nombre : ''}
                    />
                    {errors.nombre?.type === "required" && (
                      <span className="text-xs pl-1 text-danger">
                        La empresa es obligatoria.
                      </span>
                    )}
                    {errors.nombre?.type === "minLength" && (
                      <span className="text-xs pl-1 text-danger">
                        El nombre es muy corto.
                      </span>
                    )}
                    <Form.Control.Feedback type="invalid">
                      Escribe nombre cliente.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs="12" lg="6">
                  <Form.Group
                    className="mb-4"
                  >
                    <Form.Label className="labelForms">Email de contacto</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="dirección de email"
                      defaultValue={action === 'edit' ? dataEdit?.email : ''}
                      {...register("email", {
                        pattern:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                      autoComplete="off"
                    />
                    {errors.email?.type === "pattern" && (
                      <span className="text-xs pl-1 text-danger">
                        Formato de correo no válido.
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
              <Col xs="12" lg="6">
                  <Form.Group
                    className="mb-4"
                  >
                    <Form.Label className="labelForms">Persona de Contacto</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nombre completo"
                      autoFocus
                      {...register("persona", {
                        required: false,
                      })}
                      autoComplete="off"
                      defaultValue={action === 'edit' ? dataEdit?.persona : ''}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" lg="6">
                  <Form.Group
                    className="mb-4"
                  >
                    <Form.Label className="labelForms">Teléfono de Contacto</Form.Label>
                    <Form.Control
                      type="tel"
                      pattern="[0-9]{0,10}"
                      placeholder="Teléfono"
                      {...register("telefono", {
                        required: false,
                      })}
                      autoComplete="off"
                      defaultValue={action === 'edit' ? dataEdit?.telefono : ''}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Col className="mt-2">
              <Form.Group
                  className="mb-3"
                >
                  <Form.Label className="labelForms">Comentarios</Form.Label>
                  <Form.Control
                    as="textarea"
                    defaultValue={action === 'edit' ? dataEdit?.comentarios : ''}
                    rows={2}
                    {...register("comentarios")}
                  />
                </Form.Group>
              </Col>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" onClick={handleModalCreateClose} sx={{ color: "gray" }}>
                Cancelar
              </Button>
              <Button 
                type="submit"
                variant="contained"
                color="info"
                disableElevation
              >
               {action === 'edit' ? 'Actualizar' : 'Crear'}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Dialog
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
              {"Borra los datos"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Alert severity="error"><span style={{fontWeight:'bold', color: '#d32f2f'}}>Esta acción borrará de manera permanente todos los datos de la ubicación, incluidos los datos anidados</span></Alert>
                <p style={{ marginTop: '30px', fontSize:'14px'}}> Al eliminar una empresa se eliminarán todos los
            datos asociados al mismo, como usuarios, contactos...</p>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{margin:'10px'}}>
              <Button onClick={() => handleCloseDeleteModal()} sx={{color:'gray'}}>Cancelar</Button>
              <Button onClick={HandleDeleteSelected} autoFocus variant="contained" color="error" disableElevation>
                Comenzar a borrar
              </Button>
            </DialogActions>
        </Dialog>
      </div>
      
    </>
  );
};

export default ListClientes;
