import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useAuth } from '../contexts/authcontext';

function Mensajes() {
  
  const { mensaje, setMensaje } = useAuth();



  return (
    <div style={{zIndex: 9999, width: '100%', position: 'absolute',  flexDirection:'row', flex:'1', alignContent:'flex-end',alignItems:'flex-end'}} onClick={()=>{setMensaje({show: false})}}>
    <Row>
      <Col>
      <ToastContainer className="mr-1 pr-1" position='top-right' style={{width: '500px', right:'0px'}}>
        <Toast onClose={() => setMensaje({show: false})} show={mensaje.show} delay={3000} autohide={true} bg={mensaje.color} animation={false}>
          <Toast.Header closeButton={false} closeLabel='Cerrar'>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            
            <strong className="me-auto">Atención</strong>
            <small className='ml-2'>justo ahora</small>
          </Toast.Header>
          <Toast.Body className='Success'>{mensaje.texto}</Toast.Body>
        </Toast>
        </ToastContainer>
      </Col>
    </Row>
    </div>
  );

}

export default Mensajes;