import  React, {useEffect} from "react"; 
import Header from "../components/Header";
import Aside from "../components/Aside";
import Footer from "../components/Footer";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from '../contexts/authcontext';
import Lockscreen from "../components/admin/Lockscreen";


const Dashboard = () => {

  const {lock } = useAuth();
  
  if (lock) {
    return <Navigate to='/Lockscreen'/>
  }
  

  return (
    lock ? (<Lockscreen/>) : (
    <>
      <Header />
      <Aside />

      <div className="content-wrapper" style={{backgroundColor: '#FDFDFD'}}>
        {/* Main content */}
        <div className="content">
          <div className="container-fluid">
            <Outlet />
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content */}
      </div>

      <Footer />
    </>)
  );
};

export default Dashboard;
