import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { useAuth } from '../contexts/authcontext';

function Dialogs() {
  
  const { mensaje, setMensaje } = useAuth();



  return (
    <>
      <Snackbar
        open={mensaje.show}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMensaje({show: false})}
      >
        <Alert onClose={() => setMensaje({show: false})} severity={mensaje.color} variant="filled" sx={{ width: '100%' }}>
          {mensaje.texto}
        </Alert>
      </Snackbar>
    </>
    
  );

}

export default Dialogs;