import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./contexts/authcontext";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/Dashboard";
import LayoutTickets from "./pages/tickets/LayoutTickets";
import CloneInvoice from "./pages/invoices/CloneInvoice";
import NewTicket from "./components/tickets/NewTicket";
import ListTickets from "./components/tickets/ListTickets";
import Profile from "./pages/profile/Profile";
import LayoutAdmin from "./pages/administrator/LayoutAdmin";
import ListUsuarios from "./components/admin/ListUsuarios";
import Lockscreen from "./components/admin/Lockscreen";
import DetailTicket from "./components/tickets/DetailTicket";
import LayoutAdminRoot from "./pages/administrator/LayoutAdminRoot";
import NewRootSuperAdmin from "./components/admin/NewRootSuperAdmin";
import RootSuperAdmin from "./components/admin/RootSuperAdmin";
import ResetPassword from "./pages/ResetPassword";
import Inactive from "./pages/Inactive";


const App = () => {

  return (
    <div>
      <AuthProvider>
              
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          
          <Route path="dashboard/*" element={<ProtectedRoutes><Dashboard/></ProtectedRoutes>}>
             <Route path="invoices/clone/:Pad/:Id" element={<ProtectedRoutes><CloneInvoice/></ProtectedRoutes>}/>
          </Route>

          <Route path="/tickets/*" element={<ProtectedRoutes><LayoutTickets/></ProtectedRoutes>}>
              <Route path="inbox" element={<ProtectedRoutes><ListTickets/></ProtectedRoutes>}/>
              <Route path="new" element={<ProtectedRoutes><NewTicket/></ProtectedRoutes>}/>
              <Route path="detail/:Id/:Bucket" element={<ProtectedRoutes><DetailTicket/></ProtectedRoutes>}/>
          </Route>
          
          <Route path="profile/:Id" element={<ProtectedRoutes><Profile/></ProtectedRoutes>}/>

          
            <Route path="/admin/*" element={<ProtectedRoutes><LayoutAdmin/></ProtectedRoutes>}>
              <Route path="preferences" element={<ProtectedRoutes><ListUsuarios/></ProtectedRoutes>}/>
            </Route>
            <Route path="/root/*" element={<ProtectedRoutes><LayoutAdminRoot/></ProtectedRoutes>}>
                <Route path="main" element={<ProtectedRoutes><RootSuperAdmin/></ProtectedRoutes>}/>
                <Route path="new" element={<ProtectedRoutes><NewRootSuperAdmin/></ProtectedRoutes>}/>
            </Route>
            <Route path="public/:Reference" element={<ListUsuarios/>}/>
            <Route path="lockscreen" element={<Lockscreen />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="inactive" element={<Inactive />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </div>
  );
};

export default App;
