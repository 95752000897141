import React from "react";
import Header from "../../components/Header";
import Aside from "../../components/Aside";
import Footer from "../../components/Footer";
import { Outlet } from "react-router-dom";
import Mensajes from "../../components/Mensajes";
import { useAuth } from "../../contexts/authcontext";
import { Navigate } from 'react-router-dom';

const ListTickets = () => {

  const { lock } = useAuth();

  if (lock) {
    <Navigate to='/Lockscreen'/>
    return null; // No se muestra nada si lock es true
  }

  return (
    <>
      <Header />
      <Aside />

      <div className="content-wrapper" style={{backgroundColor: "#FDFDFD"}}>
        <Mensajes/>
        {/* Main content */}
        <div className="content">
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ListTickets;
