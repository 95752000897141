import React, {useEffect} from 'react'
import { useAuth } from '../contexts/authcontext';
import { Navigate } from 'react-router-dom';
import {db,auth} from '../firebase';
import { collection, query, onSnapshot, doc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import Spinner from 'react-bootstrap/Spinner';


const ProtectedRoutes = ({children}) => {
  const {user, loading, lock, setLock, loadMessages } = useAuth();
  
  useEffect(() => {
    const q = query(collection(db, "usuarios"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.doc.data().activo === false && change.doc.id == user.uid) {signOut(auth)}
      });
    });
    
    return () => {
      unsubscribe();
    };
  }, []);


  useEffect(() => {
    
     const Islocked = query(collection(db, `usuarios`));
    
     const unsubscribe = onSnapshot(Islocked, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
             
         if (change.doc.data().isLocked === true)
         {
            if (change.doc.id == auth.currentUser.uid) { setLock(true) }
         } 
      });
    });  
    return () => {
      unsubscribe();
    };
  }, [db]);

  useEffect(() => {
    const q = query(collection(db, "messages"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const destinatario = change.doc.data().destinatario;
          const filteredArray = destinatario.filter((element) => element.includes(user?.uid));

          if (filteredArray.length > 0) {
            loadMessages();
          }
        }
      });
    });
    
    return () => {
      unsubscribe();
    };
  }, []);
  
  if (loading) return (
    <div style={{ justifyContent:"center", display:"flex", alignItems:"center", height: '100vh', width:"auto", flexDirection:"column" }}>
          <div><p><Spinner animation="border" variant="primary" /></p></div>
          <div><p className='text-lg'>Cargando...</p></div>
    </div>
  )
  if (!user) return <Navigate replace to='/' />
  if (lock && user) return <Navigate replace to='/lockscreen' />
  
  if (user && !lock) return <>{children}</>
}

export default ProtectedRoutes