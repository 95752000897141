import React from "react";
import { Link } from "react-router-dom";

export default function ContentHeader(props) {
  
  const {title,subtitle,back,nav} = props;

  return (
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h4 className="m-0">{title}</h4><h6 style={{opacity:0.4}}>{subtitle}</h6>
          </div>
          {/* /.col */}
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item">
                <Link end="true" to={`${nav}`}><small>{back}</small></Link>
              </li>
              <li className="breadcrumb-item active"><small>{title}</small></li>
            </ol>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container-fluid */}
    </div>
  );
}
