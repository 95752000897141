import React, { useState, useEffect } from "react";
import ContentHeader from "../ContentHeader";
import { uid } from "uid";
import { useAuth } from "../../contexts/authcontext";
import {
  collection,
  getDocs,
  where,
  query,
  orderBy,
  addDoc,
  serverTimestamp,
  documentId,
} from "firebase/firestore";
import { ToggleButton, ToggleButtonGroup, Toolbar } from "@mui/material";
import { useForm } from "react-hook-form";
import UploadComponent from "./UploadComponent";
import { obtenerUIDProyecto, obtenerFechaYHoraActual } from "../functions";
import { Navigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const NewTicket = () => {
  const [uuid, setUuid] = useState(null);
  const { db, user, selproyecto } = useAuth();
  const [departamentos, setDepartamentos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [alignment, setAlignment] = useState("Media");
  const [data, setData] = useState({});
  const [open, setOpen] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [dataUsuarios, setDataUsuarios] = useState([]);
  const [content, setContent] = useState('');
 
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { setMensaje, lock } = useAuth();

  useEffect(() => {
    setUuid(uid(10));
    loadDepartamentos();
    loadCategorias();
    loadUser();
    
  }, []);

  useEffect(() => {
    loadDepartamentos();
    loadCategorias();
  }, [selproyecto]);

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['link', 'image'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ color: [] }, { background: [] }], // Agregar opción de color de texto
    ['clean']
  ];

  const modules = {
    toolbar: toolbarOptions
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    setData({ ...data, prioridad: newAlignment });
  };

  const HandleData = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  const resetFormulario = () => {
    setAlignment("Media");
    reset({ asunto: "", categoria: 0, departamento: 0 });
  };

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const loadUser = async () => {
    const q = query(collection(db, "usuarios"));
    const docSnap = await getDocs(q).then((querySnapshot) => {
      const datos = [];
      querySnapshot.forEach((doc) => {
        datos.push({
          id: doc.id,
          usuario: doc.data().usuario,
        });
      });
      setDataUsuarios(datos);
    });
  };
   
  const ObtenerNomUsuario = () => {
    const usuario = dataUsuarios.find((item) => item.id === user.uid);
    return usuario ? usuario?.usuario : "Not found";
  };

  const HandleCreateSubmit = async (dataForm) => {
    if (dataForm.categoria == 0) {
      setMensaje({
        show: true,
        texto: `Selecciona un Tipo de Servicio para tu TICKET`,
        color: "danger",
      });
      return;
    }

    if (dataForm.departamento == 0) {
      setMensaje({
        show: true,
        texto: `Selecciona un Departamento al que dirige el TICKET`,
        color: "danger",
      });
      return;
    }
    const agentes = [];
    const datos = {
      activo: true,
      ticketID: uid(4),
      asunto: dataForm.asunto,
      categoriaID: dataForm.categoria,
      proyectoID: obtenerUIDProyecto(),
      createdBY: user.uid,
      createBYNom: ObtenerNomUsuario(),
      createEmail: user.email,
      createEmpresaNOM: '',
      createEmpresaID: '',
      departamentoID: dataForm.departamento,
      descripcion: content, //dataForm.descripcion,
      createdate: obtenerFechaYHoraActual(),
      createTimestamp: serverTimestamp(), 
      prioridad: alignment,
      conversations: false,
      departamentoNOM: "",
      categoriaNOM: "",
      bucketIDAttachfiles: uuid,
      destinatario: agentes,
      estado: "Open",
    };

    const agentesRef = collection(db, "grupos");
    const q = query(
      agentesRef,
      where(documentId(), "==", dataForm.departamento)
    );
    const querySnapshot = await getDocs(q).then(async (querySnapshot) => {
      if (!querySnapshot.empty) {
        querySnapshot.docs[0].data().agentes.forEach((agente) => {
          agentes.push(agente);
        });

        await addDoc(collection(db, `tickets`), datos)
          .then(() => {
            setMensaje({
              show: true,
              texto: `Se ha creado el TICKET con éxto!`,
              color: "success",
            });
            resetFormulario();
          })
          .catch(() => {
            setMensaje({
              show: true,
              texto: `Ha ocurrido un problema al crear el TICKET!`,
              color: "danger",
            });
          });
      }
    });
  };

  const loadDepartamentos = async () => {
    try {
      const q = query(
        collection(db, "grupos"),
        where("proyectoID", "==", obtenerUIDProyecto()),
        where("activo", "==", true),
        orderBy("nombre", "asc")
      );
      const querySnapshot = await getDocs(q);

      const docs = [];
      docs.push({ id: 0, nombre: "Selecciona..." });
      querySnapshot.forEach((doc) => {
        docs.push({ id: doc.id, nombre: doc.data().nombre });
      });
      setDepartamentos(docs);
    } catch (error) {
      setMensaje({
        show: true,
        texto: `Ha ocurrido un problema al obtener los Departamentos! ${error}`,
        color: "danger",
      });
    }
  };

  const loadCategorias = async () => {
    try {
      const q = query(
        collection(db, "categorias"),
        where("proyectoID", "==", obtenerUIDProyecto()),
        where("activo", "==", true),
        orderBy("nombre", "asc")
      );
      const querySnapshot = await getDocs(q);
      const docs = [];
      docs.push({ id: 0, nombre: "Selecciona..." });
      querySnapshot.forEach((doc) => {
        docs.push({ id: doc.id, nombre: doc.data().nombre });
      });
      setCategorias(docs);
    } catch (error) {
      setMensaje({
        show: true,
        texto: `Ha ocurrido un problema al obtener las Categorías!`,
        color: "danger",
      });
      
    }
  };

  if (lock) {
    <Navigate to="/Lockscreen" />;
    return null; // No se muestra nada si lock es true
  }

  return (
    <>
      <ContentHeader
        title="Generar un ticket"
        subtitle={`#Track-Id-${uuid}`}
        back="Mis ticket"
        nav="/tickets/inbox"
      />
      <form onSubmit={handleSubmit(HandleCreateSubmit)}>
        <div className="card card-danger card-outline">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-6 col-sm-3 col-md-6 card-title">
                <h6>Rellena los datos del ticket</h6>
              </div>
              <div className="col-xs-6 col-sm-2 col-md-2 ml-auto text-right ">
                <button
                  type="submit"
                  formTarget="Formclone"
                  className="btn btn-primary btn-sm"
                  title="Dar de Alta"
                >
                  <i className="fa fa-plus-circle" /> Crear
                </button>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-3">
                <div className="form-group">
                  <label className="text-sm">Asunto o título del ticket</label>
                  <input
                    className="form-control"
                    type="text"
                    name="asunto"
                    onChange={HandleData}
                    {...register("asunto", {
                      minLength: 5,
                      required: true,
                      autoFocus: true,
                    })}
                  />
                  {errors.asunto?.type === "required" && (
                    <span className="text-xs pl-1 text-danger">
                      El asunto es obligatorio.
                    </span>
                  )}
                  {errors.asunto?.type === "minLength" && (
                    <span className="text-xs pl-1 text-danger">
                      El asunto es muy corto.
                    </span>
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3">
                <div className="form-group">
                  <label className="text-sm">Tipo de Servicio</label>
                  <select
                    className="form-control  select2 select2-hidden-accessible"
                    style={{ width: "100%" }}
                    name="categoria"
                    required
                                        
                    {...register("categoria")}
                  >
                    {categorias.map((option, index) => (
                      <option key={option.id} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3">
                <div className="form-group">
                  <label className="text-sm">
                    Departamento al que se dirige el ticket
                  </label>
                  <select
                    className="form-control  select2 select2-hidden-accessible"
                    style={{ width: "100%" }}
                    name="departamento"
                    required
                    {...register("departamento")}
                  >
                    {departamentos.map((option, index) => (
                      <option key={option.id} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3">
                <div className="form-group">
                  <label className="text-sm">Prioridad del ticket</label>
                  <div>
                    <ToggleButtonGroup
                      color="success"
                      value={alignment}
                      exclusive
                      onChange={handleChange}
                      aria-label="Platform"
                      size="small"
                    >
                      <ToggleButton value="Baja">Baja</ToggleButton>
                      <ToggleButton value="Media">Media</ToggleButton>
                      <ToggleButton value="Alta" color="warning">
                        Alta
                      </ToggleButton>
                      <ToggleButton value="Urgente" color="error">
                        Urgente
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                aria-rowspan={3}
              >
                <div className="form-group">
                  <label className="text-sm">
                    Descripción detallada del problema o incidencia
                  </label>
                  <ReactQuill value={content} onChange={handleEditorChange} style={{height: "300px"}}   modules={modules}  />
                </div>
              </div>
            </div>
           
            <div className="row" style={{marginTop: '50px'}}>
              <div className="col-md-3 "></div>
              <div className="col-xs-12 col-sm-12 col-md-6">
                <UploadComponent bucket={uuid} />
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default NewTicket;
