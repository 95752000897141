import moment from 'moment';

export const badge = (value) => {

    if (value == 0) return <span className="badge bg-warning text-white"><i class="far fa-clock"></i> Pend.</span>
    if (value == 1) return <span className="badge bg-success text-white"><i class="fas fa-check"></i> Pagada</span>
    if (value == 2) return <span className="badge bg-danger text-white"><i class="fas fa-check"></i> Cancelada</span>
}

export const formatNumber = (numero) => { return new Intl.NumberFormat('ES-MX').format(numero);}

export const formatFecha = (fecha) => {
    
    const date = fecha;
    const [year, month, day] = date.split('-');
    const result = [day, month, year].join('/');
    return result;
}

export const nameMeses = (mes) => {

    if(mes == '0') return 'SERV.ÚNICO';
    if(mes == '1') return 'ENERO';
    if(mes == '2') return 'FEBRERO';
    if(mes == '3') return 'MARZO';
    if(mes == '4') return 'ABRIL';
    if(mes == '5') return 'MAYO';
    if(mes == '6') return 'JUNIO';
    if(mes == '7') return 'JULIO';
    if(mes == '8') return 'AGOSTO';
    if(mes == '9') return 'SEPTIEMBRE';
    if(mes == '10') return 'OCTUBRE';
    if(mes == '11') return 'NOVIEMBRE';
    if(mes == '12') return 'DICIEMBRE';
}

export const meses = [
    {mes: '--SERV.ÚNICO--', value: '0'},
    {mes: 'ENERO', value: '1'},
    {mes: 'FEBRERO', value: '2'},
    {mes: 'MARZO', value: '3'},
    {mes: 'ABRIL', value: '4'},
    {mes: 'MAYO', value: '5'},
    {mes: 'JUNIO', value: '6'},
    {mes: 'JULIO', value: '7'},
    {mes: 'AGOSTO', value: '8'},
    {mes: 'SEPTIEMBRE', value: '9'},
    {mes: 'OCTUBRE', value: '10'},
    {mes: 'NOVIEMBRE', value: '11'},
    {mes: 'DICIEMBRE', value: '12'},
]

export async function generarCodigoAleatorio(longitud) {
    let caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let codigo = '';
    for (let i = 0; i < longitud; i++) {
      codigo += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    return codigo;
  }

export const generateRandomPassword = (length) => {
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const specialChars = '!@#$%^&*()';
    const numberChars = '0123456789';
  
    let password = '';
  
    // Asegurarse de que haya al menos un carácter de cada tipo
    password += getRandomChar(lowercaseChars);
    password += getRandomChar(uppercaseChars);
    password += getRandomChar(specialChars);
    password += getRandomChar(numberChars);
  
    // Generar el resto de la cadena aleatoria
    const remainingLength = length - 4;
    for (let i = 0; i < remainingLength; i++) {
      const charSet = lowercaseChars + uppercaseChars + specialChars + numberChars;
      password += getRandomChar(charSet);
    }
  
    // Mezclar los caracteres de forma aleatoria
    password = shuffleString(password);
  
    return password;
  };
  
  const getRandomChar = (charSet) => {
    const randomIndex = Math.floor(Math.random() * charSet.length);
    return charSet[randomIndex];
  };
  
  const shuffleString = (str) => {
    const arr = str.split('');
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr.join('');
  };
  

export function obtenerFechaActual() {
    const fechaActual = new Date();
    const dia = fechaActual.getDate();
    const mes = fechaActual.getMonth() + 1;
    const anio = fechaActual.getFullYear();
    const fechaFormateada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${anio}`;
    return fechaFormateada;
  }

export function obtenerFechaYHoraActual() {
    const fechaActual = new Date();
    const dia = fechaActual.getDate().toString().padStart(2, '0');
    const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
    const anio = fechaActual.getFullYear().toString();
    const hora = fechaActual.getHours().toString().padStart(2, '0');
    const minutos = fechaActual.getMinutes().toString().padStart(2, '0');
    const segundos = fechaActual.getSeconds().toString().padStart(2, '0');
    return `${dia}/${mes}/${anio} ${hora}:${minutos}:${segundos}`;
  }

export function tiempoTranscurrido(fecha) {
    const segundos = Math.floor((new Date() - fecha) / 1000);
    let intervalo = Math.floor(segundos / 31536000);
    if (intervalo >= 1) {
      return `Hace ${intervalo} año${intervalo === 1 ? '' : 's'}`;
    }
    intervalo = Math.floor(segundos / 2592000);
    if (intervalo >= 1) {
      return `Hace ${intervalo} mes${intervalo === 1 ? '' : 'es'}`;
    }
    intervalo = Math.floor(segundos / 86400);
    if (intervalo >= 1) {
      return `Hace ${intervalo} día${intervalo === 1 ? '' : 's'}`;
    }
    intervalo = Math.floor(segundos / 3600);
    if (intervalo >= 1) {
      return `Hace ${intervalo} hora${intervalo === 1 ? '' : 's'}`;
    }
    intervalo = Math.floor(segundos / 60);
    if (intervalo >= 1) {
      return `Hace ${intervalo} minuto${intervalo === 1 ? '' : 's'}`;
    }
    return 'Hace unos segundos';
  }

  export function localStorageAvailable() {
    try {
      const key = '__storage_test__';
      localStorage.setItem(key, key);
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  export const obtenerUIDProyecto = () => {
    const proyectoLocalStorage = localStorage.getItem('proyecto');
    const proyecto = JSON.parse(proyectoLocalStorage);
    return proyecto?.id;
  };

  export const obtenerNOMProyecto = () => {
    const proyectoLocalStorage = localStorage.getItem('proyecto');
    const proyecto = JSON.parse(proyectoLocalStorage);
    return proyecto?.nombre;
  };

  export function calcularTiempoTranscurrido(fecha) {
    const fechaActual = moment();
    const fechaPasada = moment(fecha, 'DD/MM/YYYY HH:mm:ss');
  
    const diferencia = fechaActual.diff(fechaPasada, 'seconds');
  
    if (diferencia >= 86400) { // 1 día
      const diasTranscurridos = fechaActual.diff(fechaPasada, 'days');
      if (diasTranscurridos >= 30) { // 1 mes
        const mesesTranscurridos = fechaActual.diff(fechaPasada, 'months');
        return `Hace ${mesesTranscurridos} meses`;
      } else {
        return `Hace ${diasTranscurridos} día(s)`;
      }
    } else if (diferencia >= 3600) {  // 1 hora
      return `Hace ${fechaPasada.fromNow(true)}`;
    } else if (diferencia >= 60) {    // 1 minuto
      return `Hace ${fechaPasada.fromNow(true)}`;
    } else {
      return 'justo ahora';
    }
  }

  export function localeText() {
    const localesValues = {
      columnsPanelShowAllButton: "Show All",
      columnsPanelTextFieldLabel: "Buscar columna",
      columnsPanelTextFieldPlaceholder: "Columna",
      columnMenuFilter: "Filtrar",
      columnMenuHideColumn: "Ocultar",
      columnMenuShowColumns: "Columnas...",
      footerRowSelected: count => count !== 1 ? `${count.toLocaleString()} registro(s) seleccionado(s)` : `${count.toLocaleString()} registro seleccionado`,
      footerTotalVisibleRows: visibleCount => `${visibleCount.toLocaleString()} registro(s)`,
      toolbarQuickFilterPlaceholder: "Buscar...",
      toolbarColumns: "Columnas",
      toolbarFilters: "Filtrar",
      toolbarDensity: "Densidad",
      toolbarExport: "Exportar",
      columnHeaderFiltersLabel: "Filtrar",
      noResultsOverlayLabel: "No se han encontrado coincidencias",
      noRowsLabel: "No hay datos para mostrar",
      filterOperatorContains: "Contiene",
      filterOperatorEquals: "Igual",
      filterOperatorIsEmpty: "Vacío",
      filterOperatorIsNotEmpty: "No vacío",
      filterOperatorEndsWith: "Termina con"
      
    }
    return localesValues 
  }


  
  