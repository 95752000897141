import React, { useState } from "react";
import Header from "../../components/Header";
import Aside from "../../components/Aside";
import Footer from "../../components/Footer";
import WidgetUsersAdmin from "../../components/admin/WidgetUsersAdmin";
import WidgetClientesAdmin from "../../components/admin/WidgetClientesAdmin";
import WidgetContactoCliente from "../../components/admin/WidgetContactoCliente";
import WidgetContactoInterno from "../../components/admin/WidgetContactoInterno";
import ListClientes from "../../components/admin/ListClientes";
import { Tab, Tabs } from "react-bootstrap";
import CardProyectos from "../../components/admin/CardProyectos";
import { useAuth } from "../../contexts/authcontext";
import ListGrupos from "../../components/admin/ListGrupos";
//import Mensajes from "../../components/Mensajes";
import Dialogs from "../../components/Dialogs";
import BackDrop from "../../components/BackDrop";
import ListCategorias from "../../components/admin/ListCategorias";
import { Navigate } from 'react-router-dom';
import ListUsuarios from "../../components/admin/ListUsuarios";

const LayoutAdmin = () => {
  const [key, setKey] = useState("proyectos");
  const { numProyectos, numGrupos, numClientes, numAgentes, numContactos, numCategorias, numUsuarios, lock, userInfo } = useAuth();
  
  
  if (lock) {
    return <Navigate to='/Lockscreen'/>
  }

  if (!userInfo?.isAdmin) {
    return null; 
  }
 
  return (
   
   <>
          <Header />
          <Aside />

          <div className="content-wrapper">
            <BackDrop/>
            <Dialogs/>
            {/* Main content */}
            <div className="content">
              <div className="container-fluid">
              
                <div className="row">
                  <WidgetUsersAdmin />
                  <WidgetClientesAdmin />
                  <WidgetContactoCliente />
                  <WidgetContactoInterno />
                </div>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                  style={{ height: "auto" }}
                >
                  <Tab
                    eventKey="proyectos"
                    title={"Proyectos " + "(" + numProyectos + ")"}
                  >
                    <CardProyectos />
                  </Tab>
                  <Tab
                    eventKey="clientes"
                    title={"Empresas " + "(" + numClientes + ")"}
                    disabled={numProyectos == 0}
                  >
                    {/* {numClientes > 0 ? <ListClientes /> : "no hay nada"} */}
                    <ListClientes />
                  </Tab>
                  <Tab
                    eventKey="usuarios"
                    title={"Usuarios " + "(" + numUsuarios + ")"}
                  >
                    <ListUsuarios />
                  </Tab>
                 {/*  <Tab
                    eventKey="contac-cliente"
                    title={"Contactos empresas " + "(" + numContactos + ")"}
                    disabled={numProyectos == 0}
                  >
                    <ListContacClientes/>
                  </Tab> */}
                 {/*  <Tab eventKey="agentes" title={"Agentes " + "(" + numAgentes + ")"} >
                     <ListAgentes/>
                  </Tab> */}
                  <Tab
                    eventKey="grupos"
                    title={"Mis Grupos " + "(" + numGrupos + ")"}
                  >
                    <ListGrupos/>
                  </Tab>
                  <Tab
                    eventKey="categorias"
                    title={"Categorías " + "(" + numCategorias + ")"}
                  >
                    <ListCategorias/>
                  </Tab>
                  <Tab
                    eventKey="plantillas"
                    title="Plantillas"
                  >
                    Plantillas generales
                   
                  </Tab>
                  <Tab
                    eventKey="settings"
                    title="Settings"
                  >
                   Configuraciones globales
                  </Tab>
                  
                </Tabs>
              </div>
            </div>
          </div>

          <Footer />
   
        </>
   
 );
};

export default LayoutAdmin;
