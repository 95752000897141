import './UploadComponent.css';
import { OilBarrelSharp, DeleteOutline } from '@mui/icons-material'
import React, { useState } from 'react';
import { getStorage, ref, uploadBytesResumable, deleteObject, getDownloadURL } from 'firebase/storage';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useAuth } from '../../contexts/authcontext'

const UploadComponent = ({bucket}) => {
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const { setArchivos, archivos } = useAuth();

  const handleFileUpload = (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `tickets/${bucket}/` + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed', (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

      setUploadingFiles((prevUploadingFiles) =>
        prevUploadingFiles.map((item) =>
          item.name === file.name ? { ...item, progress } : item
        )
      );
    });

    uploadTask
      .then((snapshot) => {
        
        setUploadingFiles((prevUploadingFiles) =>
          prevUploadingFiles.map((item) =>
            item.name === file.name ? { ...item, uploaded: true, error: false } : item
          )
        );
       // Guardar el nombre del archivo en el estado archivos
      setArchivos((prevArchivos) => [...prevArchivos, snapshot.ref.name]);
      })
      .catch((error) => {
        console.error(error)
        setUploadingFiles((prevUploadingFiles) =>
          prevUploadingFiles.map((item) =>
            item.name === file.name ? { ...item, uploaded: false, error: true } : item
          )
        );
      });

    setUploadingFiles((prevUploadingFiles) => [
      ...prevUploadingFiles,
      { name: file.name, progress: 0, uploaded: false, error: false },
    ]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    files.forEach((file) => handleFileUpload(file));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => handleFileUpload(file));
  };

  const handleDeleteFile = async (fileName) => {
    
    const storage = getStorage();
    const fileRef = ref(storage, `tickets/${bucket}/` + fileName);
    await deleteObject(fileRef);

    setUploadingFiles((prevUploadingFiles) =>
      prevUploadingFiles.filter((item) => item.name !== fileName)
    );
  };

  const handleCancelUpload = (fileName) => {
    setUploadingFiles((prevUploadingFiles) =>
      prevUploadingFiles.filter((item) => item.name !== fileName)
    );
  };

  return (
    <div className="upload-container" onDrop={handleDrop} onDragOver={handleDragOver}>
      <div className="upload-content">
        <div className="upload-icon">
          {/* SVG icon */}
          <CloudUploadIcon fontSize='70' />
        </div>
        <input
          type="file"
          id="file-input"
          multiple
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
        />
        <label style={{color:'gray'}} htmlFor="file-input" className="upload-label">
          Arrastra y suelta archivos aquí o haz <span style={{color: "blue", cursor:"pointer"}}>clic</span> para seleccionar archivos
        </label>
        {uploadingFiles.length > 0 ? (
          <ul className="upload-file-list">
            {uploadingFiles.map((file) => (
              <li
                key={file.name}
                className={`upload-file-item${file.error ? ' upload-file-error' : ''}`}
              >
                <p className="upload-file-name">{file.name}</p>
                {file.uploaded && file.progress === 100 ? (
                  <div className="upload-file-success">
                    <p className="upload-file-status">OK</p>
                    <a
                      className="upload-file-delete"
                      onClick={() => handleDeleteFile(file.name)}
                      //href=""
                    >
                      {/* Delete icon */}
                      Eliminar
                    </a>
                  </div>
                ) : (
                  <div className="upload-progress-bar">
                    <div
                      className="upload-progress"
                      style={{ width: `${file.progress}%` }}
                    ></div>
                    <a
                      className="upload-file-cancel"
                      onClick={() => handleCancelUpload(file.name)}
                      href="#"
                    >
                      Cancelar
                    </a>
                  </div>
                )}
                {file.error && (
                  <p className="upload-file-status">ERROR</p>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p className="upload-text">Sin archivos seleccionados</p>
        )}
      </div>
    </div>
  );
};

export default UploadComponent;