import React, { useState } from "react";
import Header from "../../components/Header";
import Aside from "../../components/Aside";
import Footer from "../../components/Footer";
import WidgetUsersAdmin from "../../components/admin/WidgetUsersAdmin";
import WidgetClientesAdmin from "../../components/admin/WidgetClientesAdmin";
import WidgetContactoCliente from "../../components/admin/WidgetContactoCliente";
import WidgetContactoInterno from "../../components/admin/WidgetContactoInterno";
import { useAuth } from "../../contexts/authcontext";
import Dialogs from "../../components/Dialogs";
import BackDrop from "../../components/BackDrop";
import { Navigate, Outlet } from 'react-router-dom';
import RootSuperAdmin from "../../components/admin/RootSuperAdmin";

const LayoutAdminRoot = () => {
  const [key, setKey] = useState("proyectos");
  const { lock, userInfo } = useAuth();
  
  
  if (lock) {
    return <Navigate to='/Lockscreen'/>
  }

  if (!userInfo?.isAdmin) {
    return null; 
  }
 
  return (
   
   <>
          <Header />
          <Aside />

          <div className="content-wrapper">
            <BackDrop/>
            <Dialogs/>
            {/* Main content */}
            <div className="content">
              <div className="container-fluid">
              
                <div className="row">
                  <WidgetUsersAdmin />
                  <WidgetClientesAdmin />
                  <WidgetContactoCliente />
                  <WidgetContactoInterno />
                </div>
                  {/* <RootSuperAdmin/> */}
                  <Outlet/>
              </div>
            </div>
          </div>

          <Footer />
   
        </>
   
 );
};

export default LayoutAdminRoot;
