import React from "react";

export default function Footer() {
    const fecha = new Date();
  return (
    <footer className="main-footer dark-footer">
      <strong className="text-sm">
        Copyright © 2012-{fecha.getFullYear()} <a href="https://itcglobal.mx"> Itcglobal.mx</a>.
      </strong>
      All rights reserved.
      <div className="float-right d-none d-sm-inline-block text-xs">
        <b>Version</b> 1.0.1
      </div>
    </footer>
  );
}
