import React, {useEffect} from "react";
import { Link, NavLink} from "react-router-dom";
import { useAuth } from "../contexts/authcontext";
import {db,auth} from '../firebase';
import { doc, updateDoc, query, collection, getDocs, where, getDoc } from "firebase/firestore";
import { calcularTiempoTranscurrido, obtenerUIDProyecto } from "./functions";
import {Avatar} from "@mui/material";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import MessageIcon from '@mui/icons-material/Message';

export default function Header() {
  
  const {Logout,setLock, notification, notiMesagges, messagesNum, loadMessages, Setproyectos, proyectos, setSelProyecto} = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [changeproyecto, setChangeproyecto] = useState({id: obtenerUIDProyecto, nombre: '' });

  useEffect(() => {
    loadproyectos(); 
    loadMessages();
  }, [])
  
    
  const HandleLogout = async () => {
    Logout(auth);
  }

  const handleLock = async () => {
    setLock(true);
    const Locked = doc(db,`usuarios/${auth.currentUser.uid}`);
    const update = await updateDoc(Locked, { isLocked: true })

    return
  }
  
  const muestraProyecto = () => {

    const objetoJSON = localStorage.getItem('proyecto');
    const objetoRecuperado = JSON.parse(objetoJSON);
    return "Repositorio: "+ objetoRecuperado.nombre;
  }

  const loadproyectos = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "usuarios", user.uid);
      const userDocSnap = await getDoc(userDocRef);
  
      if (userDocSnap.exists()) {
        const proyectosPermitidos = userDocSnap.data().proyectos || [];
         
        const q = query(collection(db, "proyectos"),
          where("__name__", "in", proyectosPermitidos),
          where("activo", "==", true)
        );
  
        const querySnapshot = await getDocs(q);
        const datos = [];
        querySnapshot.forEach((doc) => {
          datos.push({
            id: doc.id,
            nombre: doc.data().nombre,
          });
        });
        Setproyectos(datos);
       
      }
    }
  };
  

  const ChangeProy = ()  => {
    const obj = changeproyecto;
    const objJSON = JSON.stringify(obj);
    localStorage.setItem('proyecto', objJSON);
    setSelProyecto({uid: changeproyecto.id, nombre: changeproyecto.nombre} );  
    setShowModal(false);
  }

  const handleChangeProyecto = (event) => {
    setChangeproyecto({id: event.target.value, nombre: event.target.options[event.target.selectedIndex].text });
  };

  return (
    <>
    <nav className="main-header navbar navbar-expand navbar-dark">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <i className="fas fa-bars" />
          </a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
        <Link to="/dashboard"><span className="nav-link">Home</span></Link>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <Link to="/contacto"><span className="nav-link">Contactos</span></Link>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <span onClick={()=>setShowModal(true)} style={{cursor:"pointer"}}><span className="nav-link">{ muestraProyecto() }</span></span>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        {/* Navbar Search */}
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="navbar-search"
            href="#"
            role="button"
          >
            <i className="fas fa-search" />
          </a>
          <div className="navbar-search-block">
            <form className="form-inline">
              <div className="input-group input-group-sm">
                <input
                  className="form-control form-control-navbar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <div className="input-group-append">
                  <button className="btn btn-navbar" type="submit">
                    <i className="fas fa-search" />
                  </button>
                  <button
                    className="btn btn-navbar"
                    type="button"
                    data-widget="navbar-search"
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>
        {/* Messages Dropdown Menu */}
        <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="#">
            <i className="far fa-comments" />
            <span className="badge badge-danger navbar-badge">{messagesNum}</span>
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            { notiMesagges?.map((item)=>(
            <NavLink end to= {`/tickets/detail/${item?.url}`} className="dropdown-item">
              {/* Message Start */}
              <div className="media">
              { item?.senderNom && (
              <Avatar sx={{ bgcolor: "orange",width: 35, height: 35, marginRight: 2 }}>{item?.senderNom.substring(0,2).toUpperCase()}</Avatar>)}
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    {"Actividad Ticket: "+item?.ticket}
                    <span className="float-right text-sm text-danger">
                      <i className="fas fa-star" />
                    </span>
                  </h3>
                  <p className="text-sm"> {item?.mensaje}</p>
                  <p className="text-sm text-muted">
                    <i className="far fa-clock mr-1" /> {calcularTiempoTranscurrido(item?.createAt)}
                  </p>
                </div>
              </div>
             
              {/* Message End */}
            </NavLink>
            ))}
            <div className="dropdown-divider" />
            { notiMesagges?.length < 1 && (
                <div className="dropdown-item">
                  <MessageIcon style={{ color: "Gray"}} />
                  <span style={{color: "Gray", marginLeft:"10px"}}>Tienes {notiMesagges?.length} mensajes nuevos</span>
                </div>)
              } 
              <div className="dropdown-divider" />        
            <a href="#" className="dropdown-item dropdown-footer">
               Ver todos los mensajes
            </a>
          </div>
        </li>
        {/* Notifications Dropdown Menu */}
        <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="#">
            <i className="far fa-bell" />
            <span className="badge badge-warning navbar-badge">15</span>
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span className="dropdown-item dropdown-header">
              15 Notifications
            </span>
            <div className="dropdown-divider" />
            <a href="#" className="dropdown-item">
              <i className="fas fa-envelope mr-2" /> 4 new messages
              <span className="float-right text-muted text-sm">3 mins</span>
            </a>
            <div className="dropdown-divider" />
            <a href="#" className="dropdown-item">
              <i className="fas fa-users mr-2" /> 8 friend requests
              <span className="float-right text-muted text-sm">12 hours</span>
            </a>
            <div className="dropdown-divider" />
            <a href="#" className="dropdown-item">
              <i className="fas fa-file mr-2" /> 3 new reports
              <span className="float-right text-muted text-sm">2 days</span>
            </a>
            <div className="dropdown-divider" />
            <a href="#" className="dropdown-item dropdown-footer">
              See All Notifications
            </a>
          </div>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="fullscreen"
            href="#"
            role="button"
          >
            <i className="fas fa-expand-arrows-alt" />
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="lock"
            onClick={handleLock}
            role="button"
          >
            <i class="fa fa-lock" aria-hidden="true"></i>
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="logout"
            onClick={HandleLogout}
            role="button"
          >
            <i className="fas fa-sign-out-alt" />
          </a>
        </li>
      </ul>
    </nav>

    <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header>
            <Modal.Title><h5>Selecciona Repositorio:</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
           <select value={changeproyecto.id} onChange={handleChangeProyecto} style={{padding:'5px', width: '100%', cursor:'pointer'}}>
            { proyectos.map((item,index) =>
            <option key={index} value= {item?.id}>{item?.nombre}</option>
            )}
           </select>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cerrar
            </Button>
            {
              proyectos.length > 1 &&
           (
            <Button variant="primary" type="button" onClick={ChangeProy}>
              Cambiar
            </Button>
             )}
          </Modal.Footer>
      </Modal>
    </>
  );
}
