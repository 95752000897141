import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/authcontext";
import { auth, db } from "../../firebase";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  orderBy,
  serverTimestamp,
} from "firebase/firestore";
import { uploadBytes, getDownloadURL } from "firebase/storage";
import { useForm } from "react-hook-form";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import ContentHeader from "../ContentHeader";
import { Col, Row, Card, Modal } from "react-bootstrap";
import { Avatar, Collapse } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import {
  obtenerFechaYHoraActual,
  calcularTiempoTranscurrido,
} from "../functions";
import moment from "moment";
import FileList from "./FileList";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { Divider, Chip } from "@mui/material";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import {
  DeleteForeverOutlined,
  DeleteOutlineRounded,
} from "@mui/icons-material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TextField, Button } from "@mui/material";
import UploadComponent from "./UploadComponent";
import RefreshIcon from "@mui/icons-material/Refresh";
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 


const DetailTicket = () => {

  const { Id, Bucket } = useParams();
  const {
    user,
    archivos,
    userInfo,
    setNotification,
    notification,
    setMensaje,
  } = useAuth();
  const [data, setData] = useState([]);
  const [cabTicket, setcabTicket] = useState({});
  const [prioridad, setPrioridad] = useState();
  const [estado, setEstado] = useState();
  const [expanded, setExpanded] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [ordenBy, setOrdenBy] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [showModal, setShowModal] = useState(false);
  const [showModalPrioridad, setShowModalPrioridad] = useState(false)
  const [showModalEstado, setShowModalEstado] = useState(false)
  const [currentImage, setCurrentImage] = useState("");
  const [currentFile, setCurrentFile] = useState({});
 
  const elementRef = useRef(null);
  const campoTextoRef = useRef(null);
  const storage = getStorage();
  const location = useLocation();

  const handleExpand = () => {
    setExpanded(!expanded);
    scrollToElement();
  };

  const returnOrder = () => {
    if (ordenBy) {
      return "desc";
    } else {
      return "asc";
    }
  };

  const toggleOrder = () => {
    setOrdenBy(!ordenBy);
  };

  const scrollToElement = () => {
    elementRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    detalleTicket();
  }, []);

  useEffect(() => {
    loadClientNom();
    generalTicket();
    detalleTicket();
    loadUser();
  }, [ordenBy]);

  useEffect(() => {
    loadClientNom();
    generalTicket();
    detalleTicket();
    loadUser();
  }, [location.pathname]);

  const handleFileClick = async (nombreArchivo) => {
    const storageRef = ref(storage, `tickets/${Bucket}/${nombreArchivo}`);
    const downloadURL = await getDownloadURL(storageRef);
    setCurrentFile({ file: nombreArchivo, url: downloadURL });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModalPrioridad = () => {
    setShowModalPrioridad(false);
    setPrioridad(cabTicket?.prioridad);
  };

  const handleCloseModalEstado = () => {
    setShowModalEstado(false);
    setEstado(cabTicket?.estado);
  };

  const handleChangePrioridad = (event) => {
    setPrioridad(event.target.value); 
    setShowModalPrioridad(true)
  };

  const handleChangeEstado = (event) => {
    setEstado(event.target.value); 
    setShowModalEstado(true)
  };

  const handleConfirmPrioridad = async () => {
    const ticket = doc(db,`tickets/${Id}`);
    const update = await updateDoc(ticket, { prioridad: prioridad })
    .then(() =>{
      setShowModalPrioridad(false);
      setMensaje({
        show: true,
        texto: `Se ha cambiado la Prioridad con éxito.`,
        color: "warning",
      })
    .catch(() => {
      setMensaje({
        show: true,
        texto: `Ha ocurrido un error al cambiar la prioridad.`,
        color: "danger",
      })
    });
    })
  }

  const handleConfirmEstado = async () => {
    const ticket = doc(db,`tickets/${Id}`);
    const update = await updateDoc(ticket, { estado: estado })
    .then(() =>{
      setShowModalEstado(false);
      setMensaje({
        show: true,
        texto: `Se ha cambiado el Estado con éxito.`,
        color: "warning",
      })
    .catch(() => {
      setMensaje({
        show: true,
        texto: `Ha ocurrido un error al cambiar el Estado.`,
        color: "danger",
      })
    });
    })
  }

  const getFileExtension = (nombreArchivo) => {
    if (nombreArchivo) {
      return nombreArchivo.split(".").pop().toLowerCase();
    }
  };

  const generalTicket = async () => {
    const docRef = doc(db, "tickets", Id);
    const docSnap = await getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          setcabTicket(doc.data());
          setPrioridad(doc.data().prioridad);
          setEstado(doc.data().estado);
        } else {
          setMensaje({
            show: true,
            texto: `Ha ocurrido un problema al recuperar el TICKET!`,
            color: "danger",
          });
        }
      })
      .catch((error) => {
        setMensaje({
          show: true,
          texto: `Ha ocurrido un problema al recuperar el TICKET!`,
          color: "danger",
        });
      });
    // console.log(cabTicket.bucketIDAttachfiles)
  };

  const loadUser = async () => {
    const q = query(collection(db, "usuarios"));
    const docSnap = await getDocs(q).then((querySnapshot) => {
      const datos = [];
      querySnapshot.forEach((doc) => {
        datos.push({
          id: doc.id,
          usuario: doc.data().usuario,
          //  correo: doc.data().correo,
        });
      });
      setUsuarios(datos);
    });
  };

  const loadClientNom = async () => {
    const q = query(collection(db, "contactclientes"));
    const docSnap = await getDocs(q).then((querySnapshot) => {
      const datos = [];
      querySnapshot.forEach((doc) => {
        datos.push({
          id: doc.id,
          nombre: doc.data().nombre,
        });
      });
      setClientes(datos);
    });
  };

  const muestraUserName = (Id) => {
    const usuario = usuarios.find((item) => item.id === Id);
    return usuario ? usuario?.usuario : "Not found";
  };

  const muestrEmailUser = (Id) => {
    const usuario = usuarios.find((item) => item.id === Id);
    //  return correo ? correo?.correo.substring(0,2).toUpperCase() : "Not found";
    return usuario
      ? usuario?.usuario.substring(0, 2).toUpperCase()
      : "Not found";
  };

  const detalleTicket = async () => {
    const q = query(
      collection(db, `tickets/${Id}/conversations`),
      orderBy("createTimestamp", returnOrder())
    );
    const docSnap = await getDocs(q)
      .then((querySnapshot) => {
        const datos = [];
        querySnapshot.forEach((doc) => {
          datos.push({
            id: doc.id,
            createAt: doc.data().createAt,
            mensaje: doc.data().mensaje,
            senderID: doc.data().senderID,
            attachFiles: doc.data().attachFiles,
            senderNom: doc.data().senderNom,
          });
        });
        setData(datos);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const HandleCreateSubmit = async (data) => {
    // setDisableButton(false);
    const createComment = await addDoc(
      collection(db, `tickets/${Id}/conversations`),
      {
        activo: true,
        senderID: user.uid,
        mensaje: data.comment,
        createAt: obtenerFechaYHoraActual(),
        attachFiles: archivos,
        createTimestamp: serverTimestamp(), // Utiliza serverTimestamp() para obtener la fecha y hora actual del servidor
        senderNom: "",
      }
    )
      .then(async () => {
        setMensaje({
          show: true,
          texto: `Se ha creado el COMENTARIO con éxto!`,
          color: "success",
        });
        const createmessage = await addDoc(
        collection(db, `messages`),
          {
            read: false,
            senderID: user.uid,
            mensaje: data.comment,
            createAt: obtenerFechaYHoraActual(),
            createTimestamp: serverTimestamp(),
            senderNom: "",
            url: `${Id}/${Bucket}`,
            destinatario: cabTicket.destinatario,
            ticket: cabTicket.ticketID,

         });
        setExpanded(false);
        detalleTicket();
        reset({ comment: "" });
        const beepSound = new Audio("../../../assets/beep.mp3");
        beepSound.play();
      })
      .catch((error) => {
        setMensaje({
          show: true,
          texto: `Ha ocurrido un problema al crear el Comentario!`,
          color: "danger",
        });
      });

    const updateConversacion = doc(db, `/tickets/${Id}`);
    const update = await updateDoc(updateConversacion, { conversations: true });
  };

  return (
    <>
      {/* <Container> */}
      <ContentHeader
        title="Detalle del Ticket"
        subtitle={`#Track-Id-${Id}`}
        back="Crear Ticket"
        nav="/tickets/new"
      />
      <Row>
        <Col sm={8} md={8} xs={12}>
          <Card border="danger" style={{ width: "100%" }}>
            <Card.Header>General #TKT-{cabTicket.ticketID} <span style={{paddingLeft:'5px', cursor: 'pointer'}}></span></Card.Header>
            <Card.Body>
              <Card.Title color="gray" style={{ overflowWrap: 'break-word', wordBreak: 'break-word'}}>
                <b>{cabTicket.asunto}</b>
              </Card.Title>
              <Card.Text>
                <Row>
                  <Col sm={8} style={{ marginTop: "3px", color: "gray" }}>
                    From: {cabTicket.createBYNom} ({cabTicket.createEmail})
                  </Col>
                  <Col sm={4}>
                    {" "}
                    <span
                      className="text-xs"
                      style={{ color: "gray", float: "right" }}
                    >
                      {cabTicket.createdate}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} style={{ marginTop: "3px", color: "gray" }}>
                    Company: {cabTicket.createEmpresaNOM}{" "}
                  </Col>
                </Row>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Categoría</TableCell>
                        <TableCell>Prioridad</TableCell>
                        <TableCell align="right">Tiempo (trans.)</TableCell>
                        <TableCell>Estado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Administración
                        </TableCell>
                        <TableCell align="right">
                          <select className="form-control" value={prioridad} onChange={handleChangePrioridad} disabled={ estado == 'Close'  } >
                            <option value='Baja'>Baja</option>
                            <option value='Media'>Media</option>
                            <option value='Alta'>Alta</option>
                            <option value='Urgente'>Urgente</option>
                         </select>
                        </TableCell>
                        <TableCell align="right">
                          {calcularTiempoTranscurrido(cabTicket?.createdate)}
                        </TableCell>
                        <TableCell align="right">
                          <select className="form-control" value={estado} onChange={handleChangeEstado}>
                            <option value='Open' hidden={ estado == 'Close' || estado == 'Re-Open' }>Open</option>
                            <option value='Close'>Close</option>
                            <option value='Re-Open' hidden={ estado == 'Open' }>Re-Open</option>
                          </select>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ marginTop: "5px" }}>
                
                <ReactQuill value={cabTicket?.descripcion} readOnly  toolbar={false}/>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          
          <Card border="primary" style={{ width: "100%" }}>
            <Card.Header>
              Actividad reciente
              <span
                style={{ color: "gray", float: "right", cursor: "pointer" }}
              >
                <AddCommentIcon
                  onClick={handleExpand}
                  titleAccess="Crear comentario..."
                />
              </span>
              <span
                style={{
                  color: "gray",
                  float: "right",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <SortByAlphaIcon
                  onClick={toggleOrder}
                  titleAccess="Ordenar Ascendente/Descendente..."
                />
              </span>
              <span
                style={{
                  color: "gray",
                  float: "right",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <RefreshIcon
                  onClick={detalleTicket}
                  titleAccess="Volver a cargar..."
                />
              </span>
            </Card.Header>
            <Card.Body>
            {data.length < 1 && (
              <div style={{ flex: 1, textAlign: 'center', color: 'gray'}}>
                <span>** No hay actividad **</span>
              </div>
            )}
              {data.map((conversacion) => (
                <Card.Text>
                  <Row style={{ marginBottom: "10px" }}>
                    <Col sm={1}>
                      <Avatar sx={{ bgcolor: "orange", width: 40, height: 40 }}>
                        {muestrEmailUser(conversacion?.senderID)}
                      </Avatar>
                    </Col>
                    <Col sm={10}>
                      <div style={{ flex: 1, flexDirection: "row" }}>
                        <div style={{ color: "#007bff", fontWeight: "bold" }}>
                          {" "}
                          {
                            //conversacion?.senderNom
                            muestraUserName(conversacion?.senderID)
                          }
                        </div>
                        <div style={{ fontSize: "12px", color: "gray" }}>
                          Enviado{" "}
                          {moment(
                            conversacion?.createAt,
                            "DD/MM/YYYY HH:mm:ss"
                          ).format("DD MMM YYYY HH:mm")}
                        </div>
                      </div>
                    </Col>
                    {/* <Col sm={1}><ReplyIcon titleAccess='Responder mensaje' style={{cursor:'pointer', color:'grey'}} onClick={handleExpand}/></Col> */}
                  </Row>
                  <span
                    style={{
                      color: "grey",
                      fontSize: "15px",
                      marginTop: "10px",
                    }}
                  >
                    {conversacion?.mensaje}
                  </span>
                  <div
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    {conversacion.attachFiles?.length > 0
                      ? conversacion.attachFiles.map((files) => (
                          <span
                            style={{
                              color: "#007bff",
                              fontSize: "13px",
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleFileClick(files)}
                          >
                            <AttachFileIcon fontSize="14" />
                            {files}
                          </span>
                        ))
                      : ""}
                  </div>
                  <Divider>
                    {" "}
                    <Chip
                      size="small"
                      label={calcularTiempoTranscurrido(conversacion?.createAt)}
                    />
                  </Divider>
                </Card.Text>
              ))}
              <Collapse in={expanded}>
                <div
                  style={{
                    marginTop: "15px",
                    flex: 1,
                    flexDirection: "column",
                  }}
                  ref={elementRef}
                >
                  <form onSubmit={handleSubmit(HandleCreateSubmit)}>
                    <Row>
                      <Col md={11} xs={12}>
                        <TextField
                          id="outlined-multiline-static"
                          label="Comentarios"
                          multiline
                          autoFocus
                          rows={5}
                          fullWidth
                          {...register("comment", {
                            minLength: 3,
                            required: true,
                            autoFocus: true,
                          })}
                        />
                        {errors.comment?.type === "required" && (
                          <span className="text-xs pl-1 text-danger">
                            Es obligatorio escribir un comentario.
                          </span>
                        )}
                        {errors.comment?.type === "minLength" && (
                          <span className="text-xs pl-1 text-danger">
                            El comentario es demasiado corto.
                          </span>
                        )}
                        {/* Modal para mostrar la imagen */}
                        <Modal show={showModal} onHide={handleCloseModal}>
                          <Modal.Body>
                            {getFileExtension(currentFile.file) === "pdf" ? (
                              <embed
                                src={currentFile.url}
                                width="100%"
                                height="600px"
                                type="application/pdf"
                              />
                            ) : (
                              <img src={currentFile.url} alt="Archivo" />
                            )}
                          </Modal.Body>
                        </Modal>
                      </Col>
                      <Col md={1} xs={12}>
                        <Button
                          type="submit"
                          style={{ marginBottom: "5px", width: "30px" }}
                          variant="contained"
                          disableElevation
                          color="success"
                          size="medium"
                          title="Enviar"
                        >
                          <SendIcon />
                        </Button>
                        <Button
                          style={{ marginBottom: "5px" }}
                          variant="contained"
                          color="error"
                          disableElevation
                          size="medium"
                          onClick={() => setExpanded(false)}
                          title="Cancelar"
                        >
                          <CloseIcon />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ marginTop: "10px" }}>
                        <div></div>
                        <UploadComponent bucket={Bucket} />
                      </Col>
                    </Row>
                  </form>
                </div>
              </Collapse>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={4} md={4} xs={12}>
          <Card border="primary" style={{ width: "100%" }}>
            <Card.Header>Cronología</Card.Header>
            <Card.Body>
              <Card.Title>Primary Card Title</Card.Title>
              <Card.Text>
                <Timeline position="alternate">
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: "auto 0" }}
                      align="right"
                      variant="body2"
                      color="text.secondary"
                    >
                      9:30 am
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot>
                        <FastfoodIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                      <Typography variant="h6" component="span">
                        Eat
                      </Typography>
                      <Typography>Because you need strength</Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: "auto 0" }}
                      variant="body2"
                      color="text.secondary"
                    >
                      10:00 am
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot color="primary">
                        <LaptopMacIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                      <Typography variant="h6" component="span">
                        Code
                      </Typography>
                      <Typography>Because it&apos;s awesome!</Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot color="primary" variant="outlined">
                        <HotelIcon />
                      </TimelineDot>
                      <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                      <Typography variant="h6" component="span">
                        Sleep
                      </Typography>
                      <Typography>Because you need rest</Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
                      <TimelineDot color="secondary">
                        <RepeatIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                      <Typography variant="h6" component="span">
                        Repeat
                      </Typography>
                      <Typography>
                        Because this is the life you love!
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card border="primary" style={{ width: "100%" }}>
            <Card.Header>Asignaciones</Card.Header>
            <Card.Body>
              <Card.Title>Primary Card Title</Card.Title>
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card border="primary" style={{ width: "100%" }}>
            <Card.Header>Archivos adjuntos</Card.Header>
            <Card.Body>
              <FileList bucket={Bucket} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* </Container>  */}

      <Modal show={showModalPrioridad} onHide={handleCloseModalPrioridad}>
                          <Modal.Body>
                           Confirma para cambiar la prioridad del Ticket: #{cabTicket?.ticketID} a: <b>{prioridad}</b>.
                          
                          </Modal.Body>
                          <Modal.Footer>
                          <Button onClick={handleCloseModalPrioridad}>Cancelar</Button>
                          <Button onClick={handleConfirmPrioridad}>Confirmar</Button>
                          </Modal.Footer>
                        </Modal>

                        <Modal show={showModalEstado} onHide={handleCloseModalEstado}>
                          <Modal.Body>
                           Confirma para cambiar el Estado del Ticket: #{cabTicket?.ticketID} a: <b>{estado}</b>.
                          
                          </Modal.Body>
                          <Modal.Footer>
                          <Button onClick={handleCloseModalEstado}>Cancelar</Button>
                          <Button onClick={handleConfirmEstado}>Confirmar</Button>
                          </Modal.Footer>
                        </Modal>
    </>
  );
};

export default DetailTicket;
