import React,  {useEffect,} from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import Aside from "../../components/Aside";
import Footer from "../../components/Footer";

const Profile = () => {

  const params = useParams();
  // Initialize Firebase Cloud Messaging and get a reference to the service
  
  return (
    <>
    <Header />
    <Aside />

    <div className="content-wrapper">
      {/* Main content */}
      <div className="content">
        <div className="container-fluid">
          Mi perfil: {params.Id}
        </div>
      </div>
    </div>

    <Footer />
  </>
  )
}

export default Profile