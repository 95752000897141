import React from "react";
import { Link } from "react-router-dom";

const Inactive = () => {
  
  document.body.style.backgroundColor = '#FDFDFD';
  return (
    <div className="content-wrapper" style={{backgroundColor: "#FDFDFD"}}>
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>500 Login Error</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/login">Home</Link>
                </li>
                <li className="breadcrumb-item active">500 Error Login</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content" style={{marginTop: '20vh'}}>
        <div className="error-page">
          <h2 className="headline text-warning"> 500</h2>
          <div className="error-content">
            <h3>
              <i className="fas fa-exclamation-triangle text-warning" /> Oops!
              Cuenta deshabilitada.
            </h3>
            <p>
              Existe un problema con tu cuenta para iniciar sesión, pudiera deberse a un mantenimiento del Sistema
              en estos momenetos <Link to="/login">regresa al Inicio</Link> o consulta con el Administrador del sistema.
            </p>
          </div>
          {/* /.error-content */}
        </div>
        {/* /.error-page */}
      </section>
      {/* /.content */}
    </div>
  );
};

export default Inactive;
