import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { auth, db } from "../../firebase";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
  where, orderBy, query
} from "firebase/firestore";
import { useAuth } from "../../contexts/authcontext";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "@mui/material";
import "@fontsource/roboto/300.css";
import { obtenerFechaYHoraActual } from "../functions";
import { useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import Badge from "@mui/material/Badge";
import { FormControlLabel, IconButton } from "@mui/material";
import { blue, pink, grey } from "@mui/material/colors";
import { Edit } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AddCircleOutlineOutlined, CopyAll } from "@mui/icons-material";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Stack } from "@mui/system";
import { obtenerUIDProyecto, obtenerNOMProyecto } from "../functions";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';


const ListCategorias = () => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [data, setData] = useState([]);
  const [showModalCreate, setshowModalCreate] = useState(false);
  const [showModalEdit, setshowModalEdit] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [refreshTable, setrefreshTable] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);

  const { selproyecto, user, setNumCategorias, setMensaje } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: dataEdit
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    loadCategorias();
  }, [selproyecto]);

  useEffect(() => {
    reset(dataEdit);
  }, [dataEdit, reset]);

  const loadCategorias = async () => {
    setrefreshTable(true);
    const q = query(collection(db, 'categorias'), where("proyectoID", "==", obtenerUIDProyecto()), orderBy("nombre", "asc"));
    const querySnapshot = await getDocs(q)
    .then((querySnapshot)=>{
      const datos = [];
      querySnapshot.forEach((doc) => {
        datos.push({
          id: doc.id,
          nombre: doc.data().nombre,
          comentarios: doc.data().comentarios,
          activo: doc.data().activo === true ? true : false,
        });
      });
      setData(datos);
      setNumCategorias(datos.length);
      setrefreshTable(false);
    })
    .catch((error)=>{
      setMensaje({show: true, texto: `Ha ocurrido un problema de lectura!` , color: 'danger'})
    })
  };

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "nombre", headerName: "Categoría", width: 200 },
    { field: "activo", headerName: "Activa", width: 75, align: "center", 
    renderHeader: () => <div style={{ textAlign: 'center', color: "#333" }}><b>Activa</b></div>,
    renderCell: (param) => {
      let icon = "";
      if (param.row.activo == true) {icon = <DoneAllIcon style={{ color: "green"}}/>} else  {icon = <RemoveDoneIcon style={{ color: "red"}}/>}
      
      return (
        <div style={{ textAlign: 'center' }}>{icon}</div>
      )
    }
    },
    { field: "comentarios", headerName: "Comentarios", width: 400 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      disableButton,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatEdit index={params.row.id} />
          </div>
        );
      },
    },
  ];

  const MatEdit = ({ index }) => {
    const handleEditClick = async () => {
      setrefreshTable(true)
      //Buscamos el registro por su uid
      const docRef = doc(db, `categorias`, `${index}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
       
        setDataEdit({id: docSnap.id, nombre: docSnap.data().nombre, comentarios: docSnap.data().comentarios, activo: docSnap.data().activo})
        setshowModalEdit(true);   
        setrefreshTable(false); 
      } else {
        
        setMensaje({show: true, texto: `Ha ocurrido un problema de lectura!` , color: 'danger'})
      }
     
    };

    return (
      <FormControlLabel
        control={
          <IconButton
            color="secondary"
            aria-label="Editar..."
            onClick={handleEditClick}
            title="Editar categoría"
          >
            <Edit style={{ color: blue[500] }} />
          </IconButton>
        }
      />
    );
  };

  const HandleCreateSubmit = async (data) => {
    setDisableButton(true);
    const createCliente = await addDoc(
      collection(db,`categorias`),
      {
        activo: true,
        nombre: data.nombre.toUpperCase(),
        createdBY: user.uid,
        comentarios: data.descripcion,
        createdate: obtenerFechaYHoraActual(),
        proyectoID: obtenerUIDProyecto()
      }
    ).then(() => {
      setMensaje({show: true, texto: `Se ha creado la Categoría: ${data.nombre.toUpperCase()} con éxito!` , color: 'success'})
      setshowModalCreate(false);
      loadCategorias();
      reset();
      setDisableButton(false);
    }).catch(()=>{
      setMensaje({show: true, texto: `Se ha producido un error al crear la Categoría.` , color: 'danger'})
      setDisableButton(false);
    })

    
  };

  const HandleEditSubmit = async (data) => {
        
        //setDisableButton(true);
        const actProyecto = doc(db, `categorias/${dataEdit.id}`);

        const update = await updateDoc(actProyecto,  {nombre: data.nombre.toUpperCase(), comentarios: data.comentarios,
                activo: JSON.parse(data.activo)})
        .then(function() {
            setshowModalEdit(false);
            loadCategorias();
            setMensaje({show: true, texto: `Se ha modificado la Categoría: ${data.nombre.toUpperCase()} con éxito!` , color: 'success'});
            reset();
         })
         .catch((error)=>{
          setMensaje({show: true, texto: `Se ha producido un error al modificar la Categoría.` , color: 'danger'})
         })
  }

  const handleModalCreateClose = () => {
    setshowModalCreate(false);
    reset();
  };

  const handleModalEditClose = () => {
    setshowModalEdit(false);
    reset();
  };

  const handleCloseDeleteModal = () => {
    setopenDeleteModal(false);
  };

  const HandleDeleteSelected = async () => {
    setrefreshTable(true);
    selectionModel.map((categoria) => {
      const deleteSeleccionado = doc(db,"categorias",categoria);
      deleteDoc(deleteSeleccionado);
    });
    setMensaje({show: true, texto: `Se ha eliminado la categoría(s) seleccionada(s) con éxito!` , color: 'success'})
    loadCategorias();
    setopenDeleteModal(false);
  };

  return (
    <>
      <div className="col-12 col-sm-12 col-md-12">
        <div className="card card-primary card-outline">
          <div className="card-header">
            <h3 className="card-title" style={{marginTop: '8px'}}>Categorías del proyecto </h3>
            <div className="card-tools">
              <div className="btn-group">
                {selectionModel.length > 0 && (
                <>
                  <button
                    type="button"
                    className="btn btn-tool"
                    title="copiar Categorías seleccionadas."
                    onClick={() => setopenDeleteModal(true)}
                    >
                    <Badge
                      badgeContent={selectionModel.length}
                      sx={{ color: blue[500] }}
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                      <CopyAllIcon />
                    </Badge>
                  </button>
                  
                  <button
                    type="button"
                    className="btn btn-tool"
                    title="Eliminar Categorías seleccionados."
                    onClick={() => setopenDeleteModal(true)}
                  >
                    <Badge
                      badgeContent={selectionModel.length}
                      sx={{ color: pink[500] }}
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                      <DeleteIcon />
                    </Badge>
                  </button>
                </>
                  
                )}

                <div>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    Opciones
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setshowModalCreate(true);
                      }}
                    >
                      <AddCircleOutlineOutlined sx={{ color: blue[500] }} />
                      <span className="text-sm pl-2"> Nueva Categoría</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setopenDeleteModal(true);
                      }}
                      disabled={selectionModel.length < 1}
                    >
                      <DeleteIcon sx={{ color: pink[500] }} />{" "}
                      <span className="text-sm pl-2">
                        Eliminar ({selectionModel.length}) seleccionados
                      </span>
                    </MenuItem>
                  </Menu>
                </div>
              </div>

              <button
                type="button"
                className="btn btn-tool"
                data-card-widget="maximize"
              >
                <i className="fas fa-expand"></i>
              </button>
            </div>
          </div>
          <div style={{ height: 'auto', width: "100%" }}>
            <DataGrid
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 5, page: 0 },
                },
              }}
              disableRowSelectionOnClick
              columns={columns}
              rows={data}
              autoHeight
              pageSizeOptions={[5, 10, 25, 50]}
              components={{
                noRowsLabel: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No se han encontrado Categorías
                  </Stack>
                ),
                noResultsOverlayLabel: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No se han encontrado coincidencias
                  </Stack>
                ),
              }}
              localeText={{
                columnsPanelShowAllButton: "Show All",
                columnsPanelTextFieldLabel: "Buscar columna",
                columnMenuFilter: "Filtrar",
                columnMenuHideColumn: "Ocultar",
                columnMenuShowColumns: "Columnas...",
                noRowsLabel: "No se han encontrado Categorías"
              }}
              rowsPerPageOptions={[5]}
              checkboxSelection
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              loading={refreshTable}
              selectionModel={selectionModel}
            />
          </div>
        </div>
      </div>

      <div>
        <Modal show={showModalCreate} onHide={handleModalCreateClose} size="lg">
          <Form
            onSubmit={handleSubmit(HandleCreateSubmit)}
          >
            <Modal.Header>
              <Modal.Title>
                Crear nueva Categoría{" "}
                <span className="muted text-md">
                  (Proy: {obtenerNOMProyecto()})
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Nombre completo</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nombre completo"
                      autoFocus
                      name="nombre"
                      {...register("nombre", {
                        minLength: 3,
                        required: true,
                        autoFocus: true,
                      })}
                      autoComplete="off"
                      //defaultValue={dataEdit?.nombre}
                    />
                    {errors.nombre?.type === "required" && (
                      <span className="text-xs pl-1 text-danger">
                        El nombre es obligatorio.
                      </span>
                    )}
                    {errors.nombre?.type === "minLength" && (
                      <span className="text-xs pl-1 text-danger">
                        El nombre es muy corto.
                      </span>
                    )}
                    <Form.Control.Feedback type="invalid">
                      Escribe nombre completo.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Col className="mt-2">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Anotaciones</Form.Label>
                  <Form.Control
                    type="textarea"
                    name="descripcion"
                    {...register("descripcion", { rows: 4 })}
                    rows={3}
                  />
                </Form.Group>
              </Col>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" onClick={handleModalCreateClose}>
                Cancelar
              </Button>
              <Button type="submit" disabled={disableButton}>
                Crear
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal show={showModalEdit} onHide={handleModalEditClose} size="lg">
          <Form
            id="createCliente"
            onSubmit={handleSubmit(HandleEditSubmit)}
          >
            <Modal.Header>
              <Modal.Title>
                Editar Categoría{" "}
                <span className="muted text-md">
                  (Proy: {obtenerNOMProyecto()})
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Nombre completo</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nombre completo"
                      autoFocus
                      name="nombre"
                      {...register("nombre", {
                        minLength: 3,
                        required: true,
                        autoFocus: true,
                      })}
                      autoComplete="off"
                                         
                    />
                    {errors.nombre?.type === "required" && (
                      <span className="text-xs pl-1 text-danger">
                        El nombre es obligatorio.
                      </span>
                    )}
                    {errors.nombre?.type === "minLength" && (
                      <span className="text-xs pl-1 text-danger">
                        El nombre es muy corto.
                      </span>
                    )}
                    <Form.Control.Feedback type="invalid">
                      Escribe nombre completo.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                
              </Row>
              <Row>
                <Col xs lg="2">
                <Form.Label>Estado</Form.Label>
                    <select className="form-control" {...register("activo")}>
                        <option value={true}>Activo</option>
                        <option value={false}>Inactivo</option>
                    </select>
                </Col>
              </Row>
              <Col className="mt-2">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Anotaciones</Form.Label>
                  <Form.Control
                    type="textarea"
                    name="descripcion"
                    {...register("comentarios", { rows: 4 })}
                    rows={3}
                  />
                </Form.Group>
              </Col>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" onClick={handleModalEditClose}>
                Cancelar
              </Button>
              <Button type="submit">
                Guardar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

       {/*  <Modal
          show={openDeleteModal}
          onHide={handleCloseDeleteModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirma para eliminar...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>Advertencia:</b> Al eliminar un agente se eliminarán todos los
            datos asociados al mismo, como tickets...
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => handleCloseDeleteModal()}>Cancelar</Button>
            <Button sx={{ color: pink[500] }} onClick={HandleDeleteSelected}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal> */}

        <Dialog
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
              {"Borra los datos"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Alert severity="error"><span style={{fontWeight:'bold', color: '#d32f2f'}}>Esta acción borrará de manera permanente todos los datos de la ubicación, incluidos los datos anidados</span></Alert>
                <p style={{ marginTop: '30px', fontSize:'14px'}}>Se borrará el registro de todos los tickets asociados al mismo del proyecto actual.</p>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{margin:'10px'}}>
              <Button onClick={() => handleCloseDeleteModal()} sx={{color:'gray'}}>Cancelar</Button>
              <Button onClick={HandleDeleteSelected} autoFocus variant="contained" color="error" disableElevation>
                Comenzar a borrar
              </Button>
            </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ListCategorias;
