import React from 'react';
import { useAuth } from '../contexts/authcontext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { blue,lightBlue } from '@mui/material/colors';


function BackDrop() {
  
  const { backdrop } = useAuth();

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
       // onClick={() => setBackdrop({show: false})}
      >
        <CircularProgress sx={{color: lightBlue}} />
        <p style={{fontSize: '20px', marginLeft: '10px', marginTop:'10px'}}>Procesando...</p>
      </Backdrop>
    </>
    
  );

}

export default BackDrop;