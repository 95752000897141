import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  
  document.body.style.backgroundColor = '#FDFDFD';
  return (
    <div className="content-wrapper" style={{backgroundColor: "#FDFDFD"}}>
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>404 Error Page</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/login">Home</Link>
                </li>
                <li className="breadcrumb-item active">404 Error Page</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content" style={{marginTop: '20vh'}}>
        <div className="error-page">
          <h2 className="headline text-warning"> 404</h2>
          <div className="error-content">
            <h3>
              <i className="fas fa-exclamation-triangle text-warning" /> Oops!
              Página no encontrada.
            </h3>
            <p>
              La página que estás intentando navegar no existe o no está funcionando correctamente
              en estos momenetos <Link to="/login">regresa al Inicio</Link> o verifica que la URL introducida es correcta.
            </p>
          </div>
          {/* /.error-content */}
        </div>
        {/* /.error-page */}
      </section>
      {/* /.content */}
    </div>
  );
};

export default NotFound;
