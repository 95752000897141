import React from "react";
import { useAuth } from "../../contexts/authcontext";
import { Avatar } from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { db, auth } from "../../firebase";
import { useForm } from "react-hook-form";
import { doc, updateDoc } from "firebase/firestore";

const Lockscreen = () => {
  const { setLock, userInfo, Logout, user } = useAuth();
  const navigate = useNavigate();
  const fecha = new Date();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: "" });
  document.body.style.backgroundColor = "#e9ecef";

  const handleUnLook = async (data) => {
    
    const Locked = doc(db,`usuarios/${auth.currentUser.uid}`);
    const update = await updateDoc(Locked, { isLocked: false });

    await signInWithEmailAndPassword(
      auth,
      auth.currentUser?.email,
      data.password
    )
      .then(() => {
        setLock(false);
        navigate("/dashboard");
      })
      .catch(() => {
        alert("Se produjo un error, verifica la password introducida.");
      });
  };

  if (!user) {return <Navigate to='/login'/>; return null}

  return (
    <div className="hold-transition lockscreen">
      <div className="lockscreen-wrapper">
        <div className="lockscreen-logo">
          
            <b>Admin</b>LTE
         
        </div>
        {/* User name */}
        <div className="lockscreen-name">{userInfo?.usuario}</div>
        {/* START LOCK SCREEN ITEM */}
        <div className="lockscreen-item">
          {/* lockscreen image */}
          <div className="lockscreen-image">
            <Avatar sx={{ bgcolor: "gray", width: "80", height: "80" }}>
              {auth.currentUser?.email.substring(0, 2).toUpperCase()}
            </Avatar>
          </div>
          {/* /.lockscreen-image */}
          {/* lockscreen credentials (contains the form) */}
          <form
            className="lockscreen-credentials"
            onSubmit={handleSubmit(handleUnLook)}
          >
            <div className="input-group">
              <input
                type="password"
                className="form-control"
                placeholder="password"
                {...register("password", {
                  required: true,
                  autoFocus: true,
                })}
              />

              <div className="input-group-append">
                <button type="submit" className="btn">
                  <i className="fas fa-arrow-right text-muted" />
                </button>
              </div>
            </div>
          </form>
          {/* /.lockscreen credentials */}
          {errors.password?.type === "required" && (
            <span className="text-xs pl-1 text-danger">
              Introduce tu password.
            </span>
          )}
          
        </div>

        {/* /.lockscreen-item */}
        <div className="help-block text-center">
          Introduce tu password para continuar la sesión
        </div>
        <div className="text-center">
          <Link to="/login" onClick={Logout}>
            <span className="nav-link">
              O inicia sesión con usuario diferente
            </span>
          </Link>
        </div>
        <div className="lockscreen-footer text-center text-sm mt-2">
          <b>
            Copyright © 2012-{fecha.getFullYear()}{" "}
            <a href="https://itcglobal.mx"> Itcglobal.mx</a>.
          </b>
          <br />
          All rights reserved
        </div>
      </div>
    </div>
  );
};

export default Lockscreen;


