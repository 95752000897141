import React, { useEffect, useState } from "react";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import { Col, Row, Modal } from "react-bootstrap";
import LinkIcon from "@mui/icons-material/Link";

const convertBytesToSize = (bytes) => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
};

const FileList = ({ bucket }) => {
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const storage = getStorage();
    const folderRef = ref(storage, `tickets/${bucket}`); 
   
    listAll(folderRef)
      .then(async (res) => {
        const filePromises = res.items.map(async (item) => {
          const url = await getDownloadURL(item);
          const metadata = await getMetadata(item);

          return {
            name: item.name,
            url,
            size: metadata.size,
            type: metadata.contentType,
          };
        });

        Promise.all(filePromises)
          .then((fileList) => {
            setFiles(fileList);
          })
          .catch((error) => {
            alert("Error al obtener la información de los archivos:", error);
    
          });
      })
      .catch((error) => {
        alert("Error al obtener la lista de archivos:", error);
  
      });
  }, []);

  const openModal = (file) => {
    setSelectedFile(file);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedFile(null);
    setShowModal(false);
  };

  const renderFilePreview = () => {
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      return (
        <img
          src={selectedFile.url}
          alt={selectedFile.name}
          style={{
            maxWidth: "100%",
            height: "auto",
            objectFit: "contain",
          }}
        />
      );
    } else if (selectedFile && selectedFile.type === "application/pdf") {
      return (
        <iframe
          src={selectedFile.url}
          title={selectedFile.name}
          style={{ width: "100%", height: "500px", border: "none" }}
        />
      );
    } else {
      return <span>Archivo no compatible</span>;
    }
  };

  return (
    <div>
      {files.length > 0 ? (
        <div>
          {files.map((file) => (
            <div key={file.name}>
              <Row>
                <Col md={1} sm={1}>
                  <LinkIcon style={{ color: "gray" }} />
                </Col>
                <Col md={8} sm={8}>
                  <a
                    href="#"
                    onClick={() => openModal(file)}
                    style={{ fontSize: "14px" }}
                  >
                    {file.name}
                  </a>
                </Col>
                <Col md={3} sm={3} className="d-flex justify-content-end">
                  <span style={{ fontSize: "11px", color: "gray" }}>
                    {convertBytesToSize(file.size)}
                  </span>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      ) : (
        <p>No hay archivos para el Ticket".</p>
      )}

      <Modal show={showModal} onHide={closeModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{selectedFile?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderFilePreview()}</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={closeModal}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FileList;


