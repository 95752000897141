import React, { useState } from "react";
import { useAuth } from "../contexts/authcontext";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import logo from "../assets/logotipo.png";
import "./login.css";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom"; // Importa este componente si necesitas agregar un link para recuperar contraseña
import Footer from "../components/Footer";

const Login = () => {
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState(true);
  const [alertType, setAlertType] = useState("info");

  const { updateUsersTracks } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
   } = useForm();

  const HandleSubmit = async (data) => {
    setAlertType("info");
    setAlert(false);
    setError("Validando credenciales...");
    await signInWithEmailAndPassword(auth, data.email, data.password)
      .then((response) => {
        setAlertType("success");
        setError("Accediendo al sistema...")
        updateUsersTracks(response.user);
      })
      .catch((error) => {
        setAlertType("error");
        if (
          error.code === "auth/invalid-email" ||
          error.code === "auth/user-not-found"
        ) {
          setError("El correo introducido no es válido");
        }

        if (error.code === "auth/wrong-password") {
          setError("Las credenciales no son válidas");
        }

        if (error.code === "auth/internal-error") {
          setError("Error de inicio de sesión");
        }

        if (error.code === "auth/network-request-failed") {
          setError(
            "Se ha producido un error de Red. Compruebe conexión a Internet"
          );
        }

        if (error.code === "auth/user-disabled") {
          setError("Usuario DESHABILITADO.");
        }
      });
  };

  const placeholderStyles = {
    color: "#999999", // Color del texto del placeholder
    fontSize: "10px", // Tamaño del texto en el placeholder
    fontWeight: 400, // Peso de la fuente en el placeholder (400 = no negrita)
  };

  const textFieldStyles = {
    fontSize: "15px", // Tamaño del texto en el TextField
    fontWeight: 400, // Peso de la fuente en el TextField (400 = no negrita)
  };

  const inputLabelStyles = {
    fontSize: "15px", // Tamaño del texto en la etiqueta flotante
    fontWeight: 400, // Peso de la fuente en la etiqueta flotante (400 = no negrita)
    transform: "translate(12px, 12px) scale(1)", // Posición y escala de la etiqueta flotante cuando tiene foco
    "&.Mui-focused": {
      fontWeight: 700, // Peso de la fuente en la etiqueta flotante cuando tiene foco (700 = negrita)
    },
  };

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "90vh", // Altura de la ventana visible
          backgroundColor: "#FDFDFD", // Fondo gris claro
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="450px" // Altura del 90% de la ventana visible
          width="450px"
          border="1px solid #ccc 0.8" // Borde suave
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)" // Sombra ligera
          borderRadius="10px" // Bordes redondeados
          backgroundColor="#FFF" // Fondo blanco
          padding="30px" // Espacio interno
        >
          <div>
            <span>
              <h3>Login</h3>
            </span>
          </div>
          <Alert severity={alertType} sx={{ width: "100%" }} hidden={alert}>
            {error}
          </Alert>
          <form onSubmit={handleSubmit(HandleSubmit)}>
            <TextField
              label="Usuario"
              variant="outlined"
              InputLabelProps={{ style: { fontWeight: 400 } }}
              fullWidth
              autoComplete="off"
              margin="normal"
              {...register("email", {
                minLength: 6,
                required: true,
                autoFocus: true,
              })}
            />

            {errors.email?.type === "required" && (
              <span className="text-xs pl-1 text-danger">
                Escribe el username.
              </span>
            )}
            {errors.email?.type === "minLength" && (
              <span className="text-xs pl-1 text-danger">
                El username es demasiado corto.
              </span>
            )}

            <TextField
              label="Contraseña"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              InputLabelProps={{ style: { fontWeight: 400 } }}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handlePasswordVisibility}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register("password", {
                required: true,
              })}
            />
            {errors.password?.type === "required" && (
              <span className="text-xs pl-1 text-danger">
                Escribe la password.
              </span>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disableElevation
              sx={{ marginTop: "15px", fontWeight: "400" }}
              size="large"
            >
              Iniciar sesión
            </Button>
            <div
              style={{
                textAlign: "right",
                marginTop: "15px",
                fontSize: "15px",
              }}
            >
              <Link
                to="/reset-password"
                style={{ color: "gray", textDecoration: "none" }}
              >
                ¿Olvidaste tu contraseña?
              </Link>
            </div>
          </form>
        </Box>
       
      </div>
      <Footer style={{ width: '100%'}} />
    </>
 
  );
};

export default Login;
